import React, { useEffect, useState } from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import { FooterFeedbackSubmission, getTokenResource } from "../services/message.service";

export const PageFooter = () => {
  // State to manage the visibility of the feedback modal
  const [showFeedbackModal, setShowFeedbackModal] = useState(false);
  // State for feedback category and details
  const [submitFeedback, setSubmitFeedback] = useState({});
  const [feedbackCategory, setFeedbackCategory] = useState('');
  const [feedbackDetail, setFeedbackDetail] = useState('');
  const { getAccessTokenSilently, isAuthenticated, user } = useAuth0();
  const [csrfToken, setCSRFToken] = useState(null);

  useEffect(() => {
    let isMounted = true;
  
    const getCSRFToken = async () => {
      const accessToken = await getAccessTokenSilently();
      const tokenData = await getTokenResource(accessToken);
      if (isMounted) {
        if (tokenData.data && tokenData.data) {
          setCSRFToken(tokenData.data);
        } else {
          console.error('LeasesReviewPage - CSRF token could not be retrieved.');
        }
      }
    };
  
    if (isAuthenticated) {
      getCSRFToken();
    }
  }, [getAccessTokenSilently, isAuthenticated, user?.sub]);

  const handleModalSubmit = async (finalFeedback) => {
    // Assume finalFeedback is the concatenated feedback
    const updatedFeedbackCore = { ...submitFeedback, feedback: finalFeedback };
  
    try {
      const accessToken = await getAccessTokenSilently();
      const user_email = user.email;
      const user_id = user.sub;
      const csrf_token = csrfToken
      const { data, error } = await FooterFeedbackSubmission(accessToken, user_id, updatedFeedbackCore, csrf_token, user_email);
    
      if (data) {
        setShowFeedbackModal(false);
        setFeedbackCategory('');
        setFeedbackDetail('');
        setSubmitFeedback({});
      } else if (error) {
        console.error('Error updating feedback:', error.message);
        // Handle error
      }
    } catch (err) {
      console.error('Exception updating feedback:', err.message);
      // Handle exception
    }
  };

  // Creative Commons License HTML as a string  
  const ccLicenseHTML = {
    __html: `
      <p xmlns:cc="http://creativecommons.org/ns#" xmlns:dct="http://purl.org/dc/terms/" style="display: inline-flex; align-items: center; flex-wrap: wrap;">
        <a property="dct:title" rel="cc:attributionURL" href="http://www.crelytica.com" style="margin-right: 4px;">CRELYTICA Data Standard</a>
        <span style="margin-right: 4px;">by</span>
        <span property="cc:attributionName" style="margin-right: 4px;">CRELYTICA Ltd</span>
        <span style="margin-right: 4px;">is licensed under</span>
        <a href="http://creativecommons.org/licenses/by-nc-nd/4.0/?ref=chooser-v1" target="_blank" rel="license noopener noreferrer" style="display: inline-flex; align-items: center;">
          <span style="margin-right: 4px;">Attribution-NonCommercial-NoDerivatives 4.0 International</span>
          <img style="height:22px!important;margin-left:3px;vertical-align:middle;" src="https://mirrors.creativecommons.org/presskit/icons/cc.svg?ref=chooser-v1">
          <img style="height:22px!important;margin-left:3px;vertical-align:middle;" src="https://mirrors.creativecommons.org/presskit/icons/by.svg?ref=chooser-v1">
          <img style="height:22px!important;margin-left:3px;vertical-align:middle;" src="https://mirrors.creativecommons.org/presskit/icons/nc.svg?ref=chooser-v1">
          <img style="height:22px!important;margin-left:3px;vertical-align:middle;" src="https://mirrors.creativecommons.org/presskit/icons/nd.svg?ref=chooser-v1">
        </a>
      </p>`
  };
  
  

  function FeedbackModal({ isOpen, onClose, onSubmit, feedbackCategory, setFeedbackCategory }) {
    // Introduce a local state for managing detailed feedback within the modal
    const [localFeedbackDetail, setLocalFeedbackDetail] = useState('');
  
    const handleCategorySelect = (category) => setFeedbackCategory(category);
  
    const handleDetailChange = (e) => {
      setLocalFeedbackDetail(e.target.value); // Update local state
    };
  
    const handleModalSubmit = () => {
      // Use the local state for final feedback submission
      const finalFeedback = `${feedbackCategory} - ${localFeedbackDetail}`;
      onSubmit(finalFeedback); // Call the parent's submit function
      onClose(); // Close the modal
      // Reset the local state on submission
      setLocalFeedbackDetail('');
    };
  
    useEffect(() => {
      // Reset local state when the modal is closed or the feedback category changes
      setLocalFeedbackDetail('');
    }, [isOpen, feedbackCategory]);
  
    if (!isOpen) return null;
  
    return (
      <div className="enhanced-modal">
        <div className="enhanced-modal-content">
          <span className="modal-close" onClick={onClose}>&times;</span>
          {!feedbackCategory ? (
            <>
              <h2>Select a Feedback Category</h2>
              <div className="button-group">
                <button onClick={() => handleCategorySelect("I would like the product to")}>
                  I would like the product to do...
                </button>
                <button onClick={() => handleCategorySelect("The product is great")}>
                  The product is great!
                </button>
                <button onClick={() => handleCategorySelect("Complaint")}>
                  I want to make a complaint…
                </button>
                <button onClick={() => handleCategorySelect("Data standards")}>
                  I have a query regarding data standards…
                </button>
                <button onClick={() => handleCategorySelect("Privacy policy")}>
                  I have a query regarding the privacy policy of the website…
                </button>
                <button onClick={() => handleCategorySelect("Terms")}>
                  I have a query regarding the terms of the website…
                </button>
                <button onClick={() => handleCategorySelect("Data protection")}>
                  I have a query regarding data protection…
                </button>
                <button onClick={() => handleCategorySelect("Other")}>Other</button>
              </div>
            </>
          ) : (
            <>
              <h3>Selected Category: {feedbackCategory}</h3>
              <div className="modal-section">
                <label htmlFor="feedback-detail">Detailed Feedback</label>
                <textarea
                  id="feedback-detail"
                  className="modal-textarea"
                  value={localFeedbackDetail}
                  onChange={handleDetailChange}
                  placeholder="Enter detailed feedback"
                ></textarea>
              </div>
              <div className="modal-buttons-container">
                <button className="modal-button-submit" onClick={handleModalSubmit}>Submit Feedback</button>
                <button className="modal-button-change" onClick={() => setFeedbackCategory('')}>Change Category</button>
              </div>
            </>
          )}
        </div>
      </div>
    );
  }

  return (
    <footer className="page-footer">
      <div className="page-footer-grid">
        <div className="page-footer-grid__info" style={{ gridColumn: '1 / 2' }}>
          {/* Replaced direct email link with a method to open the feedback modal */}
          <p className="page-footer-message__headline">
            Please share any requests, suggestions, or feedback with us through{' '}
            <button onClick={() => setShowFeedbackModal(true)} style={{ background: 'none', border: 'none', color: '#007bff', cursor: 'pointer', padding: 0 }}>
              this portal.
            </button>
          </p>
          {/* Creative Commons License */}
      <div className="centered-license-content">
        <div dangerouslySetInnerHTML={ccLicenseHTML} />
          <p className="page-footer-message__headline">Copyright © 2021-2024 CRELYTICA, or its affiliates. All rights reserved.</p>
        </div>
      </div>
    </div>
      {/* Feedback Modal */}
      <FeedbackModal
        isOpen={showFeedbackModal}
        onClose={() => setShowFeedbackModal(false)}
        onSubmit={handleModalSubmit}
        feedbackCategory={feedbackCategory}
        setFeedbackCategory={setFeedbackCategory}
        feedbackDetail={feedbackDetail}
        setFeedbackDetail={setFeedbackDetail}
      />
    </footer>
  );
};