import React from "react";
import { NavLink } from "react-router-dom";

const baseUrl = process.env.REACT_APP_URL;

export const NavBarBrand = () => {
  return (
    <div className="nav-bar__brand">
      <NavLink to="/">
        <img
          className="nav-bar__logo"
          src={`${baseUrl}/assets/CRELYTICA_logo.png`}
          alt="CRELYTICA logo"
          width="180"
          height="36"
        />
      </NavLink>
    </div>
  );
};
