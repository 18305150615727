import { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { getTokenResource, postUserToken } from "./services/message.service";

export const useFetchCSRFToken = () => {
  const [csrfToken, setCsrfToken] = useState('');
  const { getAccessTokenSilently, isAuthenticated, user } = useAuth0();

  useEffect(() => {
    if (isAuthenticated && user) {
      const fetchCSRFToken = async () => {
        try {
          const accessToken = await getAccessTokenSilently();

          const { data: tokenData, error: tokenError } = await getTokenResource(accessToken);
          if (tokenError) {
            console.error(`useFetchCSRFToken - getTokenResource - HTTP Error: ${tokenError}`);
            return;
          }
          
          const csrfTokenFromResponse = tokenData;
          console.log('useFetchCSRFToken - getTokenResource - Retrieved CSRF Token:', csrfTokenFromResponse);
          setCsrfToken(csrfTokenFromResponse);

          const userId = user.sub;
          const { error: postError } = await postUserToken(accessToken, userId, csrfTokenFromResponse);
          if (postError) {
            console.error(`useFetchCSRFToken - postUserToken - HTTP Error: ${postError}`);
            return;
          }

        } catch (error) {
          console.error("useFetchCSRFToken - Error fetching CSRF token: ", error);
        }
      };
      
      fetchCSRFToken();
    }
  }, [getAccessTokenSilently, isAuthenticated, user, csrfToken]);

  return csrfToken;
};