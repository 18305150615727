import React from "react";
import { PageLayout } from "../components/page-layout";

export const PrivacyPolicy = () => {
  return (
    <PageLayout>
      <div className="content-layout">
        <h1 id="page-title" className="content__title">
          Privacy Policy- last updated 5th April 2024
        </h1>
        <div className="content__body">
          <section>
              <h2>1. Data Collection and Utilisation</h2>
              <p>CRELYTICA Ltd, Lease Intellect AI Ltd and Docu Intellect AI Ltd (hereby referred to as 'we') prioritise your privacy and transparently handles personal data. We collect and utilise personal data solely for purposes that are integral to our services. This includes:</p>
              <ul>
                  <li><strong>Service Provisioning:</strong> Deploying, managing, and improving our offerings.</li>
                  <li><strong>Communication:</strong> Keeping you informed about our services or events and responding to your queries, feedback and uploads that error.</li>
                  <li><strong>Security:</strong> Safeguarding our digital infrastructure and your data from fraudulent activities.</li>
                  <li><strong>Legal Compliance:</strong> Adhering to legal requirements, regulatory obligations and safeguarding rights and safety.</li>
                  <li><strong>Research and Development:</strong> Enhancing our services and innovating through document and data-driven insights, processes and automation.</li>
              </ul>
              <p>How we leverage collected data:</p>
              <ul>
                  <li>Enhancing and maintaining our services;</li>
                  <li>Delivering and personalising our services to your needs.</li>
                  <li>Offering customer support and handling inquiries and feedback.</li>
                  <li>Developing new offerings;</li>
                  <li>Updating you on product developments and offerings.</li>
                  <li>Conducting business analysis for service enhancement.</li>
                  <li>Ensuring secure and efficient operation of our services.</li>
                  <li>Enhancing security and preventing misuse;</li>
                  <li>Sharing anonymised and aggregated data for legitimate business purposes, as detailed in our sharing policy (2. Data Sharing and Disclosure).</li>
              </ul>
              <p>We may utilise your anonymised data for improving and personalising our products and services, including the application of machine learning, AI and heuristic analysis within our secure environment. This is always conducted with respect for your privacy and within the bounds of legal and ethical use.</p>
              
              <h3>Aggregated or Anonymised Data</h3>
              <p>
                  We may anonymise personal data to remove any personal identifiers, using this data to analyse service effectiveness and for other similar objectives. Such anonymised data may be shared with third parties for research purposes or made publicly available. Our methods of data aggregation comply fully with GDPR guidelines.
              </p>
              
              <h3>Personal Data Submission</h3>
              <p>
                  Personal data is collected through your interactions with our website or products, including during the account registration process. This may include, but is not limited to:
              </p>
              <ul>
                  <li>Personal and professional details (names, company affiliation, job title);</li>
                  <li>Contact information (email, phone numbers, physical address);</li>
                  <li>Financial information for service transactions;</li>
                  <li>Device and access information (IP addresses, location, browser types, operating systems).</li>
              </ul>

              <h3>Automatic Data Collection</h3>
              <p>
                  Our system may automatically gather data about your interaction with our site and services. This includes:
              </p>
              <ul>
                  <li>Device and connectivity details;</li>
                  <li>Usage patterns and preferences;</li>
                  <li>Navigation data and history within our services.</li>
              </ul>
              <p>
                  This data is primarily collected via cookies, whose settings can be managed as per our Cookie Policy.
              </p>

              <h3>User Uploaded Files & Data Creation</h3>
              <p>
                  When you upload files to our website, our system processes and generates data to enhance our products and services. This is how we manage ownership and data processing:
              </p>
              <ul>
                  <li><strong>Ownership of Uploaded Files:</strong> You retain full ownership of any files you upload to our platform. We do not claim ownership of your original content. You can permanently delete these files on the webpage</li>
                  <li><strong>Data Ownership:</strong> We own all data, insights, and information generated by our platform through the processing of your files and user interactions, reviews, verifications, edits, deletes etc. This includes anonymised or aggregated data derived from your files.</li>
                  <li><strong>Anonymisation of Data:</strong> Our system will automatically anonymise any data related to your account upon account deletion. This anonymisation process will be reviewed by a human to ensure accuracy and compliance.</li>
                  <li><strong>Intellectual Property (IP) Rights:</strong> All data, machine learning models, algorithms, and AI processes generated from the data we collect are the exclusive property of Docu Intellect AI Ltd. Docu Intellect AI Ltd reserve the right to use this data to further improve our technologies and offerings.</li>
              </ul>
              <p>
                  By using our website and uploading your files, you consent to this process and agree to the terms outlined above.
              </p>

              <h3>Call Recordings and Compliance</h3>
              <p>
                  For quality assurance, calls may be recorded in compliance with GDPR Article 6(1)(f). Recordings are kept for a standard period of 36 months, unless a request for erasure or data access is made earlier.
              </p>

              <h3>Third-party Integrations</h3>
              <p>
                  Our services may integrate with third-party products for enhanced functionality. Any data collection by third parties is subject to their respective privacy policies, and we review GDPR compliance in all such integrations.
              </p>

              <h3>Location Data</h3>
              <p>
                  Location data may be collected to tailor our services to your regional needs, such as displaying times in your local zone. We may also process this in an aggregated fashion to understand how we are preforming from a market penetration perspective in various markets and geographies.
              </p>

              <h3>Commitment to Data Security</h3>
              <p>
                  Protecting your data is a top priority. We employ advanced security measures, including encryption and secure server practices, to ensure the safety and confidentiality of your data.
              </p>
          </section>

          <section>
            <h2>2. Data Sharing and Disclosure</h2>

              <h3>Handling Your Personal Data</h3>
              <p>When you engage with our services, including our website and products, you're entrusting us with certain personal information. We're committed to handling this data responsibly and transparently. Our operations may involve transferring and processing your data in regions outside your country, such as the UK, including countries with different data protection laws. Despite these differences, we uphold stringent safeguards to ensure your personal data is protected according to the highest legal standards. Where necessary, we establish robust data transfer agreements based on standard clauses approved by the European Commission, particularly when dealing with entities inside the European Economic Area.</p>

              <h3>Sharing Personal Data</h3>
              <p>We may disclose your personal information in the following scenarios:</p>
              <ul>
                <li><strong>Service Providers and Partners:</strong> To facilitate our operations and offer you the best service, we collaborate with third-party service providers and partners. This may include sharing your data with entities responsible for data extraction accuracy, billing processing, customer support, and other essential services. These partners are permitted to use your information solely for the purposes of providing the agreed services.</li>
                <li><strong>For Analytical and Development Purposes:</strong> Occasionally, we may share data with trusted third-party vendors to understand user engagement and enhance our products and services.</li>
                <li><strong>Corporate Accounts:</strong> If you're using a corporate email address linked to your employer's domain for registration, certain usage information of your individual account may become accessible to your employer's administrator, including your email address.</li>
                <li><strong>Collaborative Use:</strong> Our platform offers collaborative features that allow sharing with third parties at your discretion. If you opt to use these features, relevant personal information like your name, email address, profile details, and any content you share will be accessible to the third parties involved in the collaboration.</li>
                <li><strong>Legal Compliance:</strong> We reserve the right to disclose your personal data if necessary to comply with legal obligations, enforce our terms and policies, protect the integrity of our services, safeguard the well-being of our clients, the public, or employees, or respond to emergencies.</li>
                <li><strong>Aggregated or Anonymised Data:</strong> We may share data in an aggregated or anonymised format, ensuring it does not directly identify you, for various legitimate business purposes.</li>
              </ul>
              <h3>How We Disclose Information</h3>
              <ul>
                <li><strong>Upon Your Consent:</strong> We disclose your information when you have explicitly consented or requested us to do so.</li>
                <li><strong>Operational Vendors:</strong> We engage with various vendors to manage aspects of our services, including data storage, hosting, document extracion accuracy reviews and payment processing.</li>
                <li><strong>Consulting Services and Feedback Related Improvements:</strong> When you use or request specific services or functionalities that involve one of our businesses, we may share your data accordingly.</li>
                <li><strong>Feature-Related Third-Party Services:</strong> When you use specific features or functionalities that involve third-party services, we may share your data accordingly.</li>
                <li><strong>Analytics and Improvement:</strong> To enhance our services, we may collaborate with vendors that assist us in understanding service usage and potential improvements.</li>
                <li><strong>Legal and Safety Obligations:</strong> We may share your data to comply with laws, respond to legal or law enforcement requests, or protect the rights and safety of our users, the public, or our employees.</li>
                <li><strong>Business Transitions:</strong> In the event of mergers, acquisitions, or any business restructure, your personal data may be shared or transferred as part of the transaction process.</li>
                <li><strong>General Business Purposes:</strong> We may share anonymised or aggregated information for legitimate business purposes, ensuring your personal identification is protected.</li>
              </ul>

              <h3>Disclosure Under Specific Circumstances</h3>
              <ul>
                <li><strong>Vendors and Service Providers:</strong> For operational efficiency, we share data with vendors and service providers for functions like hosting, customer service, IT support, email communications, document extracion accuracy reviews and web analytics.</li>
                <li><strong>Business Transactions:</strong> In cases of business transactions like mergers or restructurings, personal information may be disclosed as part of the due diligence process and transferred as a business asset.</li>
                <li><strong>Legal Requirements:</strong> We may disclose your personal data to comply with legal obligations, defend our rights, prevent fraud, ensure safety, or as legally required.</li>
                <li><strong>Affiliates:</strong> We may share your data with affiliates, adhering to this Privacy Policy.</li>
                <li><strong>Business Account Administration:</strong> If your account is linked to a business or enterprise account, account administrators may access certain information, such as your email address, to manage the account effectively.</li>
                <li><strong>Information Sharing with Other Users and Third Parties:</strong> Our services will include features that allow you to share information with other users or third parties. It's important to trust the recipients of your data when using these features.</li>
              </ul>
          </section>

          <section>
            <h2>3. User Rights under GDPR</h2>
              <ul>
                <li><strong>Withdrawal of Consent:</strong> Under GDPR, you may retract your given consent for data processing at any point. This action means we will stop processing activities that depended on this consent. Your withdrawal won't impact any processing that occurred before your consent was retracted. To withdraw, please contact us through selecting 'privacy policy' in the 'this form' link within the footer at the bottom of this page, and inform us of your wishes. Following your withdrawal, your data will be promptly erased and cease to be processed.</li>
                <li><strong>Access to Your Data:</strong> You're entitled to verify whether we're holding personal data about you. If we are, we will provide details about why and how we're using your data, the type of data, who has access to it, how long we plan to keep it, and your rights regarding changing or deleting your data. We'll also inform you about your right to complain, where your data came from if not directly from you, and if any automated decision-making processes are applied to your data.</li>
                <li><strong>Correction of Data:</strong> Should the personal data we hold about you be inaccurate or incomplete, you can correct it through messaging us through the link in the footer.</li>
                <li><strong>Deletion of Data (Right to be Forgotten):</strong> You can ask for your personal data to be erased under specific conditions. Automatically, we will delete your data when its initial collection purpose is no longer relevant or if its processing was against the law. We will delete or restrict the processing of your personal data upon these conditions: If you, as a user of our site, wish to retract your previously given consent for storing your data, it will be actioned in a reasonable time frame as required by the applicable law. OR when the need to hold your personal data is no longer relevant for the purpose it was initially stored and if there are no legal obligations (such as under commercial or tax law) preventing its deletion. OR in cases where storing your data is not permissible due to other legal grounds. Please note, however, that data related to financial transactions or required for accounting purposes is exempt from user-initiated deletion requests. To be forgotton, please contact us through selecting 'right to be forgotten' in the 'this form' link within the footer at the bottom of this page, and inform us of your wishes.</li>
                <li><strong>Restriction on Data Processing:</strong> You can request that we limit how we process your personal data, given certain legal requirements are met.</li>
                <li><strong>Data Portability:</strong> You have the right to get your personal data in a structured, standard, machine-readable format, and have the right to transfer it to another data controller.</li>
                <li><strong>Objection to Data Processing:</strong> You can object to us processing your personal data, particularly for direct marketing. To object, contact us via the details in section 9, Contact Information. Following a valid objection, we will also erase your data.</li>
                <li><strong>Complaint to Supervisory Authority:</strong> If you believe our data processing violates your rights, you're entitled to file a complaint with a supervisory authority. For UK residents, the Information Commissioner's Office (ICO) is the relevant body - (Information Commissioner's Office, Wycliffe House, Water Lane, Wilmslow, Cheshire SK9 5AF, Phone: 0303 123 1113, Email: casework@ico.org.uk, Website: www.ico.org.uk)</li>
              </ul>
          </section>
          
          <section>
            <h2>4. Data Security Measures</h2>
              <p>
              We are committed to safeguarding your personal data. We employ a blend of technical, administrative, and organisational strategies to guard your information against loss, unauthorised access or disclosure, alteration, and destruction. While we strive for the highest level of security, please be aware that no website or email transmission is completely invulnerable. Particularly, email communication to and from our service may not always be secure, prompting caution in the type of information you choose to share with us through these channels.
              </p>

              <h3>Retention and Responsibility</h3>

              <p>Your personal data is retained with us only as long as necessary for delivering our services, for legitimate business needs like dispute resolution, for ensuring safety and security, or to comply with our legal duties. The duration of retention depends on various criteria including the volume, type, and sensitivity of data, the possible risk from unauthorised usage or disclosure, our processing objectives, and legal necessities.</p>

              <h3>Your Role in Security</h3>

              <p>The protection of your personal information is further reinforced by the password you set during registration. It's crucial to select a robust, unique password and keep it confidential to prevent unauthorised access. We advise against sharing your password or allowing others to use your computer or mobile device.</p>

              <h3>Our Security Measures</h3>

              <p>To protect your personal information, we have instituted robust administrative, technical, and physical safeguards:</p>

              <ul>
                <li>Comprehensive SSL encryption (https) across all areas handling personal data.</li>
                <li>Encryption of databases for enhanced security.</li>
                <li>Secure server hosting, fortified with firewalls and physical security measures.</li>
                <li>Despite these precautions, please bear in mind that no security system is infallible. We cannot completely guarantee the absolute security of the company hosting our website, our website, nor can we ensure that your data will not be intercepted during transmission. In the unfortunate event of a security breach, we will take immediate steps to mitigate any potential damage, including notifying you through email or a public notice on our site.</li>
              </ul>

              <h3>Confidential Communications</h3>

              <p>We strive to maintain the confidentiality, integrity, and availability of your data. For email communications, it's important to note that complete confidentiality cannot be guaranteed. For sharing sensitive information, we recommend using postal services for enhanced security.</p>
              
              <h3>User Consent</h3>

              <p>By engaging with our Website, or Services, you agree to the stipulations of this Privacy Policy and authorise us to process your Personal Data as outlined herein, as well as for purposes specified at the points of data collection.</p>

              <p><strong>Revoking Consent:</strong> You may at any time choose to retract your consent for the processing and utilisation of your personal data, either in whole or in part, for future activities. To withdraw, please contact us through selecting 'privacy policy' in the 'this form' link within the footer at the bottom of this page, and inform us of your wishes. It is important to note that withdrawing consent might necessitate your provision of explicit consent on specific occasions for the use or sharing of certain personal data, should such actions be essential for you to access our full range of Products and Services.</p>

              <h3>International Data Transfers</h3>

              <p>We manage and store data on servers located in the United Kingdom, and potentially in other nations, depending on various factors including the geographical location of our users and partners. By utilising our Services, you consent to the transmission of information, which may include Customer Data, Content, and personally identifiable information, to territories potentially outside your country of residence, such as the European Union or United States. You acknowledge and accept that, in providing any Content, we are lawfully able to transfer it to the European Union or United States or another jurisdiction.</p>

              <p>In relation to data transfers from the European Union (EU), we may handle some personal identifiable information in accordance with our terms page, which forms an integral part of this Privacy Policy. This agreement incorporates the EU Standard Contractual Clauses, facilitating lawful data transfer outside of the EU.</p>
          </section>

          
          <section>
            <h2>6. Data Retention Policy</h2>
              <p>We assure you that your personal data will not be kept longer than necessary for the purposes for which it was collected. After the retention period, your data will be securely erased or anonymised. If you have questions about our data retention practices, please contact us using the details from section 9, Contact Information.</p>
              <ul>
                <li>The nature and volume of the data we collect.</li>
                <li>Our operational purposes in processing your data, including service provision and improvements, dispute resolution, and the maintenance of security and safety.</li>
                <li>Legal and regulatory obligations that require data retention.</li>
              </ul>
          </section>

          
          <section>
            <h2>7. Amendments to Our Privacy Policy</h2>
              <p>We are committed to continuously enhancing our services and ensuring that our privacy practices stay up-to-date with legal and technological developments. Accordingly, this Privacy Policy may be revised periodically.</p>

              <p>Whenever we modify this Privacy Policy, we will make these changes visible on our website, except where different notification methods are necessitated by prevailing legal requirements.</p>

              <p>The privacy practices and policies applied to any collected information are governed by the version of this Privacy Policy in effect at the time of collection. Nevertheless, we reserve the right to adjust this policy as needed. Should any amendments be considered significant, as solely determined by us, we will proactively inform you, such as through an email communication. The most current version of our Privacy Policy can always be reviewed on this page.</p>

              <p>Advancements in our website functionality, service offerings, or changes in legal/regulatory frameworks may necessitate future modifications to this Privacy Policy. Our Privacy Policy updates are often driven by feedback from our valued customers, changes within our Website or Services, and evolving regulatory landscapes. Following any updates, the "last updated" date at the top of this document will be revised accordingly. In instances of substantial changes, particularly those that alter how we handle Personal Information, we will either announce these changes before their implementation or directly inform you through a notice.</p>
          </section>

          <section>
            <h2>8. Children</h2>
            <p>
                We are committed to protecting the privacy of younger users. Our platform and services are not designed for individuals under the age of 13. Consequently, we do not intentionally gather any personally identifiable data from children below this age threshold. 
            </p>
            <p>
                In the event that we become aware of inadvertently having collected such information from a child under 13, we will promptly undertake necessary measures to remove this data from our records. Should you suspect that we might hold any information from or about a child under 13, please contact us through selecting 'gdpr' in the 'this form' link within the footer at the bottom of this page, and inform us of the child's account.
            </p>
            <p>
                Furthermore, individuals who are at least 13 years of age but under 18 must obtain consent from a parent or guardian to access and use our services. If you believe that a child under 18 has provided us with personal information, we kindly ask a parent or guardian to contact us as outlined in section 9, Contact Information. We will take appropriate steps to purge such information from our databases.
            </p>
          </section>

          
          <section>
            <h2>9. Contact Information</h2>
              <p>For any inquiries or concerns regarding our Privacy Policy, please reach out to us through the 'this form' link within the footer at the bottom of this page, and inform us of your enquiry.</p>
          </section>
          
          <section>
            <h2>10. Compliance Statements</h2>

              <p>We are dedicated to safeguarding your personal information in line with our Privacy Policy. Should you have any concerns about our adherence to this policy, or if you have queries or need to engage with us regarding your personal data, we welcome you to reach out via the 'this form' link within the footer at the bottom of this page. Upon receiving your communication, we will conduct a thorough review, take necessary actions, and respond within an expected timeframe of 30 days.</p>

              <p>We adhere to the frameworks set by the EU-U.S. Data Privacy Framework (DPF), the UK extension to the EU-U.S. DPF, and the Swiss-U.S. DPF. We commit to cooperating with the panels appointed by the EU Data Protection Authorities (DPAs), the UK Information Commissioner's Office (ICO), and the Swiss Federal Data Protection and Information Commissioner (FDPIC) regarding unresolved privacy concerns linked to the handling of personal data under these frameworks.</p>

              <p>Under the oversight of the UK Information Commissioner’s Office, we are accountable for compliance and transparency. We may disclose personal data to legal, regulatory, or national security authorities or to third parties when legally obliged.</p>

              <p>Our complaint resolution mechanisms are accessible without cost, and where applicable, damages may be awarded in accordance with prevailing legal standards.</p>

              <p>In this Privacy Policy, we also acknowledge specific rights afforded to individuals in European Territories, as defined under the General Data Protection Regulation (GDPR). Here, "European Territories" encompasses the European Economic Area (EEA), the European Free Trade Area (EFTA), and Switzerland, and continues to include the United Kingdom post-Brexit. For individuals in the UK or European Territories, CRELYTICA is your data controller, in the majority of cases. For any inquiries or to exercise your rights, please contact us.</p>
          </section>


        </div>
      </div>
    </PageLayout>
  );
};
