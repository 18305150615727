import axios from "axios";
import { callExternalApi } from "./external-api.service";
import { callExternalApiWithServerErrorAlerts } from "./external-api-upload-with-server-error-alerts.service";

const apiServerUrl = `${process.env.REACT_APP_BACKEND_URL}/api`;

export const refreshSessionId = async (user_data) => {
  const csrf_token = sessionStorage.getItem('csrf_token');
  console.log('postFileResource - Setting X-CSRFToken:', csrf_token);

  const config = {
    url: `${apiServerUrl}/refresh_session_id`, //"/refresh_session_id"
    method: "POST",
    credentials: 'include',
    headers: {
      "content-type": "application/json",
      "X-CSRF-Token": csrf_token
    },
    body: JSON.stringify({user_data}),
  };

  console.log('refreshSessionId - Sending request with config:', JSON.stringify(config));

  let data = null;
  let error = null;
  try {
    const response = await callExternalApi({ config });
    data = response.data;
    console.log('refreshSessionId - Response:', data);
  } catch (e) {
    error = e;
    console.error('refreshSessionId - Error:', e);
  }

  return {
    data: data || null,
    error,
  };
};


export const getTokenResource = async (accessToken) => {
  const config = {
    url: `${apiServerUrl}/get-csrf-token`, //'/get-csrf-token'
    method: "GET",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
    withCredentials: true,
    credentials: 'include'
  };

  try {
    const response = await axios(config);
    const csrfToken = response.headers['x-csrftoken'];
    console.log('CSRF Token:', csrfToken);
    return {
      data: csrfToken || null,
      error: null,
    };
  } catch (error) {
    console.error("Error during GET:", error.response ? error.response.data : error);
    return {
      data: null,
      error,
    };
  }
};


export const postUserToken = async (accessToken, user_id, csrfToken) => {
  const config = {
    url: `${apiServerUrl}/${user_id}/endpoint`, //'/api/<user_id>/endpoint'
    method: "POST",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`,
      'X-CSRFToken': csrfToken,
    },
    withCredentials: true,
  };

  const { data, error } = await callExternalApi({ config });

  return {
    data: data || null,
    error,
  };
};


//lease_upload page
export const getPortfolios  = async (accessToken, user_email, user_id) => {
  const config = {
    url: `${apiServerUrl}/lease_upload/get_portfolios/${encodeURIComponent(user_email)}/${encodeURIComponent(user_id)}`, //'/lease_upload/buildings/<user_email>/<user_id>'
    method: "GET",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
    withCredentials: true,
    credentials: 'include'
  };

  const { data, error } = await callExternalApi({ config });

  if (error) {
    console.error('API Error:', error);
    // Consider showing a user-friendly error message
  }

  return {
    data: data || null,
    error,
  };
};

export const getAssets = async (accessToken, user_email, user_id, portfolio) => {
  const config = {
    url: `${apiServerUrl}/lease_upload/get_assets/${encodeURIComponent(user_email)}/${encodeURIComponent(user_id)}?portfolio=${encodeURIComponent(portfolio)}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    withCredentials: true,
    credentials: 'include',
  };

  const { data, error } = await callExternalApi({ config });

  if (error) {
    console.error('API Error:', error);
    // Consider showing a user-friendly error message
  }

  return {
    data: data || null,
    error,
  };
};

export const getProperties = async (accessToken, user_email, user_id, portfolio, asset) => {
  const config = {
    url: `${apiServerUrl}/lease_upload/get_properties/${encodeURIComponent(user_email)}/${encodeURIComponent(user_id)}?portfolio=${encodeURIComponent(portfolio)}&asset=${encodeURIComponent(asset)}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    withCredentials: true,
    credentials: 'include',
  };

  const { data, error } = await callExternalApi({ config });

  if (error) {
    console.error('API Error:', error);
    // Consider showing a user-friendly error message
  }

  return {
    data: data || null,
    error,
  };
};


export const getTenantsNames = async (accessToken, user_email, user_id) => {
  const config = {
    url: `${apiServerUrl}/lease_upload/get_tenants_names/${encodeURIComponent(user_email)}/${encodeURIComponent(user_id)}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    withCredentials: true,
    credentials: 'include',
  };

  const { data, error } = await callExternalApi({ config });

  if (error) {
    console.error('API Error:', error);
    // Consider showing a user-friendly error message
  }

  return {
    data: data || null,
    error,
  };
};


export const getLeasesByTenant = async (accessToken, csrfToken, user_email, user_id, portfolio, asset, tenant) => {
  const config = {
    url: `${apiServerUrl}/lease_upload/get_leases_by_tenant/${encodeURIComponent(user_email)}/${encodeURIComponent(user_id)}?portfolio=${encodeURIComponent(portfolio)}&asset=${encodeURIComponent(asset)}&tenant=${encodeURIComponent(tenant)}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'X-CSRFToken': csrfToken,
    },
    withCredentials: true,
    credentials: 'include'
  };

  const { data, error } = await callExternalApi({ config });

  if (error) {
    console.error('API Error:', error);
    // Consider showing a user-friendly error message
  }

  return {
    data: data || null,
    error,
  };
};


export const postLeasePDF = async (accessToken, user_id, formData, csrfToken, user_email, is_commercial) => {
  const config = {
    url: `${apiServerUrl}/lease_upload/upload_pdf/${encodeURIComponent(user_email)}/${encodeURIComponent(user_id)}/${encodeURIComponent(is_commercial)}`, //'/lease_upload/upload_pdf/<user_email>/<user_id>'
    method: "POST",
    headers: {
      "Content-Type": "multipart/form-data",
      "Authorization": `Bearer ${accessToken}`,
      'X-CSRFToken': csrfToken,
    },
    data: formData,
    withCredentials: true,
  };

  console.log('postFileResource - Sending request with config:', JSON.stringify(config));
  try {
    const response = await callExternalApiWithServerErrorAlerts({ config });
    return response;
  } catch (error) {
    if (error.response && error.response.data && error.response.data.message) {
        // Extracting the message from backend response
        return { success: false, message: error.response.data.message };
    } else {
        return { success: false, message: 'An error occurred while uploading the file.' };
    }
  }
};


export const postExtractionFeedback = async (accessToken, user_id, csrfToken, user_email, is_correct, timestamp_file) => {
  const config = {
    url: `${apiServerUrl}/lease_upload/extraction_feedback/${encodeURIComponent(user_email)}/${encodeURIComponent(user_id)}/${encodeURIComponent(is_correct)}?timestamp_file=${encodeURIComponent(timestamp_file)}`,
    method: "POST", // Use POST here to match backend
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${accessToken}`,
      'X-CSRFToken': csrfToken,
    },
    withCredentials: true,
  };

  console.log('postExtractionFeedback - Sending feedback:', JSON.stringify(config));
  
  try {
    const response = await callExternalApi({ config });
    return response;
  } catch (err) {
    console.error("Error during POST:", err.message || "Unknown error occurred");
    throw err; // Rethrow the error to be caught in lease-upload-page.js
  }
};



//review_leases page
export const LeasesReviewEditLease = async (accessToken, user_id, editCore, coreId, csrf_token, user_email) => {
  const config = {
    url: `${apiServerUrl}/review_leases/update_lease/${coreId}/${encodeURIComponent(user_email)}/${encodeURIComponent(user_id)}`, ///'/review_leases/<user_id>/<user_email>/update_lease/<coreId>'
    method: "PUT",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`,
      'X-CSRFToken': csrf_token,
    },
    data: editCore,
    withCredentials: true,
  };
  console.log('LeasesReviewEditLease - Sending request with config:', JSON.stringify(config));

  const { data, error } = await callExternalApi({ config });

  return {
    data: data || null,
    error,
  };
};


export const LeasesReviewGetLeaseData = async (accessToken, user_id, lease_name, user_email) => {
  const config = {
    url: `${apiServerUrl}/review_leases/get_leases/${encodeURIComponent(user_email)}/${encodeURIComponent(user_id)}?lease_name=${encodeURIComponent(lease_name)}`, ///review_leases/<user_id>/<user_email>/get_leases'
    method: "GET",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
    withCredentials: true,
  };

  const { data, error } = await callExternalApi({ config });

  return {
    data: data || null,
    error,
  };
};


export const LeasesReviewGetLeaseNames = async (accessToken, user_id, user_email) => {
  const config = {
    url: `${apiServerUrl}/review_leases/get_lease_names/${encodeURIComponent(user_email)}/${encodeURIComponent(user_id)}`, ///review_leases/<user_id>/<user_email>/get_lease_names',
    method: "GET",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
    withCredentials: true,
  };

  const { data, error } = await callExternalApi({ config });

  return {
    data: data || null,
    error,
  };
};

export const LeasesReviewGetPortfolioNames = async (accessToken, user_email, user_id) => {
  const config = {
    url: `${apiServerUrl}/review_leases/get_portfolios/${encodeURIComponent(user_email)}/${encodeURIComponent(user_id)}`,
    method: "GET",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
    withCredentials: true,
    credentials: 'include'
  };

  try {
    const response = await axios(config); // Use axios or fetch as needed
    return {
      data: response.data,
      error: null
    };
  } catch (error) {
    console.error('API Error:', error);
    return {
      data: null,
      error
    };
  }
};


export const LeasesReviewGetAssetNames = async (accessToken, user_email, user_id, portfolio) => {
  const config = {
    url: `${apiServerUrl}/review_leases/get_assets/${encodeURIComponent(user_email)}/${encodeURIComponent(user_id)}?portfolio=${encodeURIComponent(portfolio)}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    withCredentials: true,
    credentials: 'include',
  };

  try {
    const response = await axios(config); // Use axios or fetch as needed
    return {
      data: response.data,
      error: null
    };
  } catch (error) {
    console.error('API Error:', error);
    return {
      data: null,
      error
    };
  }
};


export const LeasesReviewGetTenantNames = async (accessToken, user_email, user_id, portfolio, asset) => {
  const config = {
    url: `${apiServerUrl}/review_leases/get_tenant_names/${encodeURIComponent(user_email)}/${encodeURIComponent(user_id)}?portfolio=${encodeURIComponent(portfolio)}&asset=${encodeURIComponent(asset)}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    withCredentials: true,
    credentials: 'include',
  };

  try {
    const response = await axios(config); // Use axios or fetch as needed
    return {
      data: response.data,
      error: null
    };
  } catch (error) {
    console.error('API Error:', error);
    return {
      data: null,
      error
    };
  }
};


export const LeasesReviewGetPercentVerified = async (accessToken, user_email, user_id, lease) => {
  const config = {
    url: `${apiServerUrl}/review_leases/percent_verified/${encodeURIComponent(user_email)}/${encodeURIComponent(user_id)}?lease=${encodeURIComponent(lease)}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    withCredentials: true,
    credentials: 'include',
  };

  try {
    const response = await axios(config); // Use axios or fetch as needed
    return {
      data: response.data,
      error: null
    };
  } catch (error) {
    console.error('API Error:', error);
    return {
      data: null,
      error
    };
  }
};


export const LeasesReviewGetPercentAnswered = async (accessToken, user_email, user_id, lease) => {
  const config = {
    url: `${apiServerUrl}/review_leases/percent_answered/${encodeURIComponent(user_email)}/${encodeURIComponent(user_id)}?lease=${encodeURIComponent(lease)}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    withCredentials: true,
    credentials: 'include',
  };

  try {
    const response = await axios(config); // Use axios or fetch as needed
    return {
      data: response.data,
      error: null
    };
  } catch (error) {
    console.error('API Error:', error);
    return {
      data: null,
      error
    };
  }
};


export const LeasesReviewDeleteLease = async (accessToken, lease, user_id, user_email, csrf_token) => {
  const config = {
    url: `${apiServerUrl}/review_leases/delete_lease/${encodeURIComponent(user_email)}/${encodeURIComponent(user_id)}/${lease}`, ///review_leases/<user_id>/<email>/delete_lease/<timestampFile>'
    method: "DELETE",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`,
      'X-CSRFToken': csrf_token,
    },
    withCredentials: true,
  };

  const { data, error } = await callExternalApi({ config });

  return {
    data: data || null,
    error,
  };
};


export const LeasesReviewDownloadLease = async (accessToken, user_id, user_email) => {
  const config = {
    url: `${apiServerUrl}/review_leases/download_leases/${encodeURIComponent(user_email)}/${encodeURIComponent(user_id)}`, //'/review_leases/<user_id>/download_leases'
    method: "GET",
    responseType: 'blob',
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    withCredentials: true,
  };

  const { data, error } = await callExternalApi({ config });

  return {
    data: data || null,
    error,
  };
};


export const LeasesReviewFetchImage = async (accessToken, user_id, user_email, lease_name, currentPage) => {
  const config = {
    url: `${apiServerUrl}/review_leases/lease_copy/${encodeURIComponent(user_email)}/${encodeURIComponent(user_id)}/${lease_name}/${currentPage}`, //'/review_leases/<email>/lease_copy/<lease_name>/<int:page_number>'
    method: "GET",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
    withCredentials: true,
    responseType: 'blob',
  };

  try {
    const response = await axios(config);
    const totalPages = response.headers['x-total-pages'];
    const imageUrl = URL.createObjectURL(response.data);

    return {
      data: { imageUrl, totalPages },
      error: null,
    };
  } catch (error) {
    return {
      data: null,
      error: error.message,
    };
  }
};


export const LeaseSearchGetClauses = async (accessToken, user_email, query, csrfToken, user_id, lease_name, page = 1) => {
  const perPage = 10;  // Number of results per page
  const config = {
      url: `${apiServerUrl}/search_leases/search_clauses/${encodeURIComponent(lease_name)}/${encodeURIComponent(user_email)}/${encodeURIComponent(user_id)}?query=${encodeURIComponent(query)}&page=${page}&per_page=${perPage}`,
      method: "GET",
      headers: {
          Authorization: `Bearer ${accessToken}`,
          'X-CSRFToken': csrfToken,
      },
      withCredentials: true,
      credentials: 'include'
  };

  try {
      const { data, error } = await callExternalApi({ config });

      if (error) {
          console.error('API Error:', error);
      }

      return {
          data: data || null,
          error,
      };
  } catch (err) {
      console.error('getClausesRelatedToSearch - Error:', err);
      return { data: null, error: err };
  }
};


//lease data page
export const LeasesDataEditLease = async (accessToken, user_id, editCore, Lease, csrf_token, user_email) => {
  const config = {
    url: `${apiServerUrl}/lease_data/update_lease/${encodeURIComponent(user_email)}/${encodeURIComponent(user_id)}/${Lease}`, ///lease_data/<user_id>/update_lease/<coreId>
    method: "PUT",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`,
      'X-CSRFToken': csrf_token,
    },
    data: editCore,
    withCredentials: true,
  };

  const { data, error } = await callExternalApi({ config });

  return {
    data: data || null,
    error,
  };
};

export const LeasesDataGetLeases = async (accessToken, user_id, user_email) => {
  const config = {
    url: `${apiServerUrl}/lease_data/get_leases/${encodeURIComponent(user_email)}/${encodeURIComponent(user_id)}`, //'/lease_data/<user_id>/get_leases'
    method: "GET",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
    withCredentials: true,
  };

  const { data, error } = await callExternalApi({ config });

  return {
    data: data || null,
    error,
  };
};

export const LeasesDataDeleteLease = async (accessToken, Lease, user_id, csrf_token, user_email) => {
  const config = {
    url: `${apiServerUrl}/lease_data/delete_lease/${encodeURIComponent(user_email)}/${encodeURIComponent(user_id)}/${Lease}`, //'/lease_data/<user_id>/delete_lease/<Lease>'
    method: "DELETE",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`,
      'X-CSRFToken': csrf_token,
    },
    withCredentials: true,
  };

  const { data, error } = await callExternalApi({ config });

  return {
    data: data || null,
    error,
  };
};


export const LeasesDataDownloadLease = async (accessToken, user_id, user_email) => {
  const config = {
    url: `${apiServerUrl}/lease_data/download_leases/${encodeURIComponent(user_email)}/${encodeURIComponent(user_id)}`, //'/lease_data/<user_id>/download_leases'
    method: "GET",
    responseType: 'blob',
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    withCredentials: true,
  };

  const { data, error } = await callExternalApi({ config });

  return {
    data: data || null,
    error,
  };
};


//data_rooms page
export const getDataRoomFiles = async (accessToken, user_email, docType, user_id) => {
  const config = {
    url: `${apiServerUrl}/data_rooms/files/${docType}/${encodeURIComponent(user_email)}/${encodeURIComponent(user_id)}`, //'/data_rooms/<email>/<doc_type>/files'
    method: "GET",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
    withCredentials: true,
  };

  const { data, error } = await callExternalApi({ config });

  return {
    data: data || null,
    error,
  };
};


export const downloadDataRoomFile = async (accessToken, user_email, filename, docType, csrfToken, user_id) => {
  const config = {
    url: `${apiServerUrl}/data_rooms/download/${docType}/${filename}/${encodeURIComponent(user_email)}/${encodeURIComponent(user_id)}`, //'/data_rooms/<email>/<doc_type>/download/<filename>'
    method: "GET",
    responseType: 'blob', // Important for file download
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`,
      'X-CSRF-TOKEN': csrfToken,
    },
    withCredentials: true,
  };
  return await callExternalApi({ config });
};


export const deleteDataRoomFile = async (accessToken, user_email, filename, docType, csrfToken, user_id) => {
  const config = {
    url: `${apiServerUrl}/data_rooms/delete_file/${docType}/${filename}/${encodeURIComponent(user_email)}/${encodeURIComponent(user_id)}`, //'/data_rooms/<email>/<doc_type>/delete_file/<filename>'
    method: "DELETE",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`,
      'X-CSRF-TOKEN': csrfToken,
    },
    withCredentials: true,
  };
  return await callExternalApi({ config });
};


export const uploadDataRoomFile = async (accessToken, user_email, docType, formData, csrfToken, user_id) => {
  const config = {
    url: `${apiServerUrl}/data_rooms/upload_file/${docType}/${encodeURIComponent(user_email)}/${encodeURIComponent(user_id)}`, // '/data_rooms/<email>/<file_type>/upload_file'
    method: "POST",
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'X-CSRF-TOKEN': csrfToken,
    },
    data: formData,
    withCredentials: true,
  };
  return await callExternalApi({ config });
};


export const purchaseDataRoom = async (accessToken, user_email, csrf_token, user_id) => {
  const config = {
    url: `${apiServerUrl}/data_rooms/purchase_data_room/${encodeURIComponent(user_email)}/${encodeURIComponent(user_id)}`, //'/data_rooms/<email>/purchase_data_room',
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`,
      'X-CSRF-Token': csrf_token,
    },
    withCredentials: true,
  };

  try {
    const response = await callExternalApi({ config });
    return response.data; // Assuming response.data contains the session ID or an error
  } catch (error) {
    console.error('Error in postCheckout:', error);
    return { error };
  }
};


//page-footer.js
export const FooterFeedbackSubmission = async (accessToken, user_id, submitFeedback, csrf_token, user_email) => {
  const config = {
    url: `${apiServerUrl}/footer/submit_feedback/${encodeURIComponent(user_email)}/${encodeURIComponent(user_id)}`, ///review_leases/<user_id>/update_lease/<coreId>
    method: "PUT",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`,
      'X-CSRFToken': csrf_token,
    },
    data: submitFeedback,
    withCredentials: true,
  };

  const { data, error } = await callExternalApi({ config });

  return {
    data: data || null,
    error,
  };
};