import React, { useRef, useEffect, useState, useCallback } from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import { PageLayout } from "../components/page-layout";
import { getTokenResource, LeasesReviewGetLeaseNames, LeasesReviewFetchImage, LeaseSearchGetClauses, LeasesReviewDeleteLease, LeasesReviewDownloadLease, LeasesReviewGetPortfolioNames, LeasesReviewGetAssetNames, LeasesReviewGetTenantNames } from "../services/message.service";
import '../styles/components/table.css';
import '../styles/components/modal.css';
import '../styles/components/document-image.css';
import '../styles/general.css';
import '../styles/components/search-bar.css';
import '../styles/components/dashboard.css';

export const LeasesSearchPage = () => {
  const { getAccessTokenSilently, isAuthenticated, user } = useAuth0();
  const [csrfToken, setCSRFToken] = useState(null);
  const [message, setMessage] = useState("");

  const [allLeasesData, setAllLeasesData] = useState([]);
  const [leaseNames, setLeaseNames] = useState([]);
  const [leaseName, setLeaseName] = useState(''); 
  const [portfolioNames, setPortfolioNames] = useState([]); 
  const [portfolioName, setPortfolioName] = useState(''); 
  const [assetNames, setAssetNames] = useState([]); 
  const [assetName, setAssetName] = useState(''); 
  const [tenantNames, setTenantNames] = useState([]); 
  const [tenantName, setTenantName] = useState(''); 
  const [documentName, setDocumentName] = useState(''); 
  
  const [loading, setLoading] = useState(false);

  const [query, setQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [currentImage, setCurrentImage] = useState('');

  const stickyFilterRef = useRef(null);
  const clauseRefs = useRef([]);
  const [currentClauseIndex, setCurrentClauseIndex] = useState(0);
  const [highlightedClauseIndex, setHighlightedClauseIndex] = useState(-1);
  const [totalSearchClauses, setTotalSearchClauses] = useState(0); // New state for total search clauses
  const [bbox, setBbox] = useState('');
  const [dpi, setDPI] = useState('');
  const [pageWidth, setPageWidth] = useState('');
  const [pageHeight, setPageHeight] = useState('');  

  const pdfViewerRef = useRef(null);

  console.log("LeasesSearchPage - Initial State Set");

  useEffect(() => {
    // Ensure the user is authenticated and the user object is fully defined
    if (isAuthenticated && user?.email && user?.sub) {
      (async () => {
        try {
          const accessToken = await getAccessTokenSilently();
          const tokenData = await getTokenResource(accessToken);
          if (tokenData.data) {
            setCSRFToken(tokenData.data);
          } else {
            console.error('LeaseUploader - CSRF token could not be retrieved.');
          }

        } catch (error) {
          console.error('LeaseUploader - Error fetching portfolios or CSRF token:', error);
        }
      })();
    }
  }, [isAuthenticated, user?.email, user?.sub, getAccessTokenSilently]);

  const fetchImage = useCallback(async (page, documentName) => {
    console.log("LeasesSearchPage - fetchImage function called for page:", page, "and document:", documentName);
    if (!documentName) {
        console.error("LeasesSearchPage - document name is missing.");
        return;
    }

    const user_email = user.email;
    const user_id = user.sub;
    const accessToken = await getAccessTokenSilently();
    console.log("LeasesSearchPage - Fetching image with document name:", documentName, "and page:", page);
    const { data, error } = await LeasesReviewFetchImage(accessToken, user_id, user_email, documentName, page); // Use documentName here
    
    if (data) {      
        setDocumentName(documentName);
        setCurrentPage(page);
        setCurrentImage(data.imageUrl);
        setTotalPages(data.totalPages ? parseInt(data.totalPages, 10) : 0);
        console.log("LeasesSearchPage - Image Fetched for Page", page);

        if (pdfViewerRef.current) {
            pdfViewerRef.current.innerHTML = '';
            const imgElement = document.createElement('img');
            imgElement.src = data.imageUrl;
            imgElement.alt = `Lease page ${page}`;
            imgElement.style.width = '100%';
            pdfViewerRef.current.appendChild(imgElement);
        }
    } else {
        setMessage('Error fetching image');
    }

    if (error) {
        console.error("LeasesSearchPage - Error Fetching Image:", error);
        setMessage(JSON.stringify(error, null, 2));
    }
  }, [getAccessTokenSilently, user.email, user.sub]);

  
  // Hook to reset data and fetch new image when leaseName changes
  useEffect(() => {
    if (leaseName) {
      // Reset relevant fields before loading new data
      setBbox('');              // Clear bounding box
      setSearchResults([]);      // Clear search results
      setCurrentClauseIndex(0);  // Reset clause index
      setTotalSearchClauses(0);  // Reset total search clauses
      setMessage('');            // Clear any error messages
      setCurrentImage(null);     // Clear current image
      setLoading(false);         // Stop loading state if active
      setDocumentName('');       // Clear document name

      // Find the associated document name from allLeasesData
      const associatedLease = allLeasesData.find(lease => lease.lease_name === leaseName);
      
      if (associatedLease && associatedLease.document_name) {
        // Update the documentName state to the new document
        setDocumentName(associatedLease.document_name);

        // Fetch the first page of the document whenever the leaseName changes
        fetchImage(1, associatedLease.document_name);
      } else {
        console.error("LeasesSearchPage - No document_name found for the selected lease.");
      }
    }
  }, [leaseName, allLeasesData, fetchImage]);



  const fetchLeaseNames = useCallback(async (selectedPortfolio = '', selectedAsset = '', selectedTenant = '') => {
    console.log("LeasesSearchPage - Fetching Lease Names...");
    try {
      const accessToken = await getAccessTokenSilently();
      const user_id = user.sub;
      const user_email = user.email;
  
      const { data, error } = await LeasesReviewGetLeaseNames(accessToken, user_id, user_email);
  
      if (error) {
        console.error('LeasesSearchPage - Error fetching lease names:', error.message);
      } else if (data && data.lease_names) {
        // Sort the lease names by 'timestamp' in descending order (most recent first)
        const sortedLeaseNames = data.lease_names.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));
  
        // Filter based on selected portfolio, asset, and tenant
        const filteredLeases = sortedLeaseNames.filter(lease =>
          (!selectedPortfolio || lease.portfolio === selectedPortfolio) &&
          (!selectedAsset || lease.asset === selectedAsset) &&
          (!selectedTenant || lease.tenant === selectedTenant)
        );

        // Store all lease data to reference 'is_commercial'
        setAllLeasesData(filteredLeases);
  
        const uniqueLeaseNames = Array.from(new Set(filteredLeases.map(lease => lease.lease_name)));
  
        setLeaseNames(uniqueLeaseNames);
        console.log("LeasesSearchPage - Unique lease names fetched and sorted by timestamp:", uniqueLeaseNames);
  
        // Set the most recent lease as the default selection
        if (uniqueLeaseNames.length > 0) {
          const selectedLeaseName = uniqueLeaseNames[0]; // The most recent lease name
          setLeaseName(selectedLeaseName);
  
          const associatedDocument = filteredLeases.find(lease => lease.lease_name === selectedLeaseName);
          if (associatedDocument) {
            setDocumentName(associatedDocument.document_name);
          }
        }
      }
    } catch (err) {
      console.error("LeasesSearchPage - Error fetching lease names:", err.message);
    }
  }, [getAccessTokenSilently, user.sub, user.email]);

  
  // Function to check if the current lease is commercial
  const isCommercialLease = () => {
    const selectedLease = allLeasesData.find(lease => lease.lease_name === leaseName);
    return selectedLease ? selectedLease.is_commercial : false;
  };


  const fetchPortfolioNames = useCallback(async () => {
    console.log("LeasesSearchPage - Fetching Portfolio Names...");
    try {
      const accessToken = await getAccessTokenSilently();
      const user_id = user.sub;
      const user_email = user.email;
      const { data, error } = await LeasesReviewGetPortfolioNames(accessToken, user_email, user_id);

      if (error) {
        console.error('LeasesSearchPage - Error fetching portfolio names:', error.message);
      } else if (data && data.portfolio_names) {
        const uniquePortfolioNames = Array.from(new Set(data.portfolio_names));

        setPortfolioNames(uniquePortfolioNames);
        console.log("LeasesSearchPage - Unique portfolio names fetched:", uniquePortfolioNames);
      }
    } catch (err) {
      console.error("LeasesSearchPage - Error fetching portfolio names:", err.message);
    }
  }, [getAccessTokenSilently, user.sub, user.email]);


  const fetchAssetNames = useCallback(async () => {
    console.log("LeasesSearchPage - Fetching Asset Names...");
    try {
      const accessToken = await getAccessTokenSilently();
      const user_id = user.sub;
      const user_email = user.email;

      const { data, error } = await LeasesReviewGetAssetNames(accessToken, user_email, user_id, portfolioName);

      if (error) {
        console.error('LeasesSearchPage - Error fetching asset names:', error.message);
      } else if (data && data.asset_names) {
        const uniqueAssetNames = Array.from(new Set(data.asset_names));
        setAssetNames(uniqueAssetNames);
        console.log("LeasesSearchPage - Unique asset names fetched:", uniqueAssetNames);
      }
    } catch (err) {
      console.error("LeasesSearchPage - Error fetching asset names:", err.message);
    }
  }, [getAccessTokenSilently, user.sub, user.email, portfolioName]);


  const fetchTenantNames = useCallback(async () => {
    console.log("LeasesSearchPage - Fetching Tenant Names...");
    try {
      const accessToken = await getAccessTokenSilently();
      const user_id = user.sub;
      const user_email = user.email;

      const { data, error } = await LeasesReviewGetTenantNames(accessToken, user_email, user_id, portfolioName, assetName);

      if (error) {
        console.error('LeasesSearchPage - Error fetching tenant names:', error.message);
      } else if (data && data.tenant_names) {
        const uniqueTenantNames = Array.from(new Set(data.tenant_names));
        setTenantNames(uniqueTenantNames);
        console.log("LeasesSearchPage - Unique tenant names fetched:", uniqueTenantNames);
      }
    } catch (err) {
      console.error("LeasesSearchPage - Error fetching tenant names:", err.message);
    }
  }, [getAccessTokenSilently, user.sub, user.email, portfolioName, assetName]);


  useEffect(() => {
    if (portfolioName) {
      fetchAssetNames();  // Fetch assets related to the selected portfolio
      fetchTenantNames(); // Fetch tenants related to the selected portfolio
      fetchLeaseNames(portfolioName); // Fetch leases related to the selected portfolio
    } else {
      // If "All Portfolios" is selected, fetch all leases without any filtering
      fetchLeaseNames(); 
    }
  }, [portfolioName, fetchAssetNames, fetchTenantNames, fetchLeaseNames]);

  useEffect(() => {
    if (assetName) {
      fetchTenantNames(); // Fetch tenants related to the selected asset
      fetchLeaseNames(portfolioName, assetName); // Fetch leases related to the selected portfolio and asset
    } else {
      // If "All Assets" is selected, fetch leases without filtering by asset
      fetchLeaseNames(portfolioName);
    }
  }, [assetName, portfolioName, fetchTenantNames, fetchLeaseNames]);

  useEffect(() => {
    if (tenantName) {
      fetchLeaseNames(portfolioName, assetName, tenantName); // Fetch leases related to the selected portfolio, asset, and tenant
    } else {
      // If "All Tenants" is selected, fetch leases without filtering by tenant
      fetchLeaseNames(portfolioName, assetName);
    }
  }, [tenantName, assetName, portfolioName, fetchLeaseNames]);



  // Fetch assets based on selected portfolio
  useEffect(() => {
    const fetchAssets = async () => {
      if (portfolioName) {
        try {
          const accessToken = await getAccessTokenSilently();
          const user_id = user.sub;
          const user_email = user.email;

          const { data, error } = await LeasesReviewGetAssetNames(accessToken, user_email, user_id, portfolioName);

          if (error) {
            console.error('Error fetching assets:', error.message);
          } else {
            setAssetNames(data.asset_names || []);
          }
        } catch (err) {
          console.error('Error fetching assets:', err.message);
        }
      }
    };

    fetchAssets();
  }, [portfolioName, getAccessTokenSilently, user.sub, user.email]);


  // Fetch tenants based on selected portfolio and asset
  useEffect(() => {
    const fetchTenants = async () => {
      if (portfolioName || assetName) {
        try {
          const accessToken = await getAccessTokenSilently();
          const user_id = user.sub;
          const user_email = user.email;

          const { data, error } = await LeasesReviewGetTenantNames(accessToken, user_email, user_id, portfolioName, assetName);

          if (error) {
            console.error('Error fetching tenants:', error.message);
          } else {
            setTenantNames(data.tenant_names || []);
          }
        } catch (err) {
          console.error('Error fetching tenants:', err.message);
        }
      }
    };

    fetchTenants();
  }, [portfolioName, assetName, getAccessTokenSilently, user.sub, user.email]);

  // Update leases when a portfolio, asset, or tenant is selected or unselected
  useEffect(() => {
    const fetchLeases = async () => {
      try {
        const accessToken = await getAccessTokenSilently();
        const user_id = user.sub;
        const user_email = user.email;

        const { data, error } = await LeasesReviewGetLeaseNames(accessToken, user_id, user_email);

        if (error) {
          console.error('Error fetching lease names:', error.message);
        } else {
          const sortedLeaseNames = data.lease_names.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));
          const filteredLeases = sortedLeaseNames.filter(lease => 
            (!portfolioName || lease.portfolio === portfolioName) &&
            (!assetName || lease.asset === assetName) &&
            (!tenantName || lease.tenant === tenantName)
          );
  
          const uniqueLeaseNames = Array.from(new Set(filteredLeases.map(lease => lease.lease_name)));
    
          setLeaseNames(uniqueLeaseNames);
          console.log("LeasesSearchPage - Unique lease names fetched and sorted by timestamp:", uniqueLeaseNames);
        }
      } catch (err) {
        console.error('Error fetching lease names:', err.message);
      }
    };

    fetchLeases();
  }, [portfolioName, assetName, tenantName, getAccessTokenSilently, user.sub, user.email]);

  
  const handleHighlightClause = useCallback(async (clause) => {
    console.log("LeasesSearchPage - Clause to highlight:", clause);
    if (!clause || !clause.Page || !clause.BBOX) {
        console.error("LeasesSearchPage - Invalid clause data:", clause);
        setBbox(''); // Reset bounding box
        return;
    }

    const { document_name, Page } = clause; // Destructure to get the document_name and Page
    console.log(`LeasesSearchPage - Highlighting clause on Page ${Page} with BBOX ${clause.BBOX} in document ${document_name}`);

    const user_email = user.email;
    const accessToken = await getAccessTokenSilently();
    console.log("LeasesSearchPage - Fetching image for highlighting");

    setDocumentName(document_name);
    setCurrentPage(Page);
    
    const { data, error } = await LeasesReviewFetchImage(accessToken, user.sub, user_email, document_name, clause.Page);
    if (error) {
        console.error("LeasesSearchPage - Error fetching image:", error);
        return;
    }

    console.log("LeasesSearchPage - Image fetched successfully for highlighting");

    // Parse the page_size_inches JSON string
    let parsedPageSize = { width_in_inches: 0, height_in_inches: 0 };
    if (clause.page_size_inches) {
        try {
            parsedPageSize = JSON.parse(clause.page_size_inches);
        } catch (e) {
            console.error("LeasesSearchPage - Error parsing page_size_inches:", e);
        }
    }

    // Set the image URL, BBOX, and other variables for the hook
    setCurrentImage(data.imageUrl);
    setBbox(clause.BBOX);
    setDPI(clause.dpi || 72); // Default to 200 if dpi is not provided
    setPageWidth(parsedPageSize.width_in_inches);
    setPageHeight(parsedPageSize.height_in_inches);
    console.log("LeasesSearchPage - Clause Highlighted with Image and BBOX");
}, [getAccessTokenSilently, user.email, user.sub]);


const handleSearch = useCallback(async (newPage = 1) => {
  if (!query.trim()) {
      console.error("LeasesSearchPage - Empty search query.");
      return;
  }

  if (!leaseName) {
      console.error("LeasesSearchPage - Selected lease is undefined.");
      return;
  }

  setLoading(true);

  try {
      const accessToken = await getAccessTokenSilently();
      const user_id = user.sub;
      const user_email = user.email;
      const { data, error } = await LeaseSearchGetClauses(accessToken, user_email, query, csrfToken, user_id, leaseName, newPage);

      if (error) {
          console.error('LeasesSearchPage - API Error:', error);
        } else {
          // Set search results and relevant clauses
          setSearchResults(prevResults => newPage === 1 ? data : [...prevResults, ...data]);
          setCurrentPage(newPage);
    
          if (data.length > 0 && data[0].relevant_clauses) {
            setTotalSearchClauses(data.length); // Set total search clauses
            setCurrentClauseIndex(0);
            setHighlightedClauseIndex(0); // Set highlighted index to the first result                        
            handleHighlightClause(data[0].relevant_clauses); // Ensure proper passing of the relevant_clauses object
          } else {
            console.log("LeasesSearchPage - No relevant clauses found in search results");
          }
        }
      } catch (err) {
      console.error("LeasesSearchPage - API Request Error:", err.message);
  } finally {
      setLoading(false);
  }
}, [query, csrfToken, getAccessTokenSilently, user, leaseName, handleHighlightClause]);

  const loadMoreResults = () => {
    handleSearch(Number(currentPage) + 1);
  };

  
  useEffect(() => {
    if (searchResults.length > 0 && searchResults[0].relevant_clauses) {
      setTotalSearchClauses(searchResults.length); // Set total search clauses
      setCurrentClauseIndex(0); // Set the current clause index to the first clause
      setHighlightedClauseIndex(0); // Highlight the first clause
      handleHighlightClause(searchResults[0].relevant_clauses); // Highlight the first clause
    }
  }, [searchResults, handleHighlightClause]);   

  
  useEffect(() => {
    if (isAuthenticated && leaseNames.length === 0) {
      fetchLeaseNames();  // Always fetch lease names, regardless of is_commercial
    }
  
    // Fetch portfolios, assets, and tenants only if is_commercial is true
    if (isAuthenticated && (item => item.is_commercial)) {
      fetchPortfolioNames();
      fetchAssetNames();
      fetchTenantNames();
    }
  }, [isAuthenticated, leaseNames.length, fetchLeaseNames, fetchPortfolioNames, fetchAssetNames, fetchTenantNames]);
  
   

  useEffect(() => {
    if (currentImage && pdfViewerRef.current) {
      const img = new Image();
      img.src = currentImage;
      img.onload = () => {
        if (!pdfViewerRef.current) return; // Check if the ref is valid
  
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        if (!ctx) return;
  
        // Desired reduction factor (e.g., half the size)
        const reductionFactor = 0.37;
        // Set the canvas dimensions to match the desired reduced image size
        const reducedWidth = img.width * reductionFactor;
        const reducedHeight = img.height * reductionFactor;
  
        canvas.width = reducedWidth;
        canvas.height = reducedHeight;
  
        // Draw the image onto the canvas at the reduced size
        ctx.drawImage(img, 0, 0, reducedWidth, reducedHeight);

        // Draw the bounding box only if bbox exists
        if (bbox) {
  
          // Extract and scale the bbox
          const bboxString = Array.isArray(bbox) ? bbox.join(',') : bbox;
          const [x0, y0, x1, y1] = bboxString.split(',').map(Number);
    
          // Use the page size and dpi values to calculate the PDF dimensions
          const pdfWidth = pageWidth * dpi;
          const pdfHeight = pageHeight * dpi;
    
          // Calculate scaling factors
          const scaleX = reducedWidth / pdfWidth;
          const scaleY = reducedHeight / pdfHeight;
    
          // Scale bbox coordinates
          const scaledX0 = x0 * scaleX;
          const scaledY0 = y0 * scaleY;
          const scaledWidth = (x1 - x0) * scaleX;
          const scaledHeight = (y1 - y0) * scaleY;
    
          // Draw the bounding box
          ctx.strokeStyle = 'red';
          ctx.lineWidth = 2;
          ctx.strokeRect(scaledX0, scaledY0, scaledWidth, scaledHeight);
          console.log("LeasesSearchPage - Red Box Drawn at:", scaledX0, scaledY0, scaledWidth, scaledHeight);
        } else {
          console.log("LeasesSearchPage - No BBOX. No Red Box Drawn.");
        }
  
        // Append the canvas
        pdfViewerRef.current.innerHTML = '';
        pdfViewerRef.current.appendChild(canvas);
      };
    }
  }, [currentImage, bbox, pageWidth, pageHeight, dpi, pdfViewerRef]);


  const handleDelete = async (timestampFile) => {
    try {
        const userId = user.sub;
        const user_email = user.email;
        
        const accessToken = await getAccessTokenSilently();
        const csrf_token = csrfToken;
        console.log('LeasesSearchPage - CSRF Token at handleDelete:', csrf_token);
        const { data, error } = await LeasesReviewDeleteLease(accessToken, leaseName, userId, user_email, csrf_token);

        if (data) {
            // Call fetchLeaseNames to refresh lease names after deletion
            await fetchLeaseNames(); 
            
            // Check if the deleted lease is the current lease and update the state accordingly
            if (leaseNames.find(lease => lease.lease_name === leaseName)?.timestamp_file === timestampFile) {
                const remainingLeases = leaseNames.filter(lease => lease.timestamp_file !== timestampFile);
                if (remainingLeases && remainingLeases.length > 0) {
                    const mostRecentLease = remainingLeases[0];
                    setLeaseName(mostRecentLease.lease_name);
                    setDocumentName(mostRecentLease.document_name[0]);
                } else {
                    setLeaseName('');
                    setDocumentName('');
                }
            }

            // Optionally delay the fetchData and fetchImage calls to account for database view refresh
            setTimeout(() => {
                if (leaseName) fetchImage(1, documentName); // Fetch the first page of the selected lease
            }, 2000); // Delay by 2 seconds
        }

        if (error) {
            console.error(error);
            console.error('LeasesSearchPage - handleDelete error:', error.message);
        }
    } catch (err) {
        console.error(err.message);
    }
  };



  const scrollToStickyFilter = () => {
    if (stickyFilterRef.current) {
      stickyFilterRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  };

  
  const handleNextPage = () => {
    if (currentPage < totalPages) {
      const nextPage = Number(currentPage) + 1;
      setCurrentPage(nextPage);
      fetchImage(nextPage, documentName);
      setBbox('');
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      const prevPage = Number(currentPage) - 1;
      setCurrentPage(prevPage);
      fetchImage(prevPage, documentName);
      setBbox('');
    }
  };

  const handleNextClause = () => {
    scrollToStickyFilter(); // Scroll to the sticky filter first
  
      if (currentClauseIndex < totalSearchClauses - 1) {
        const nextClauseIndex = currentClauseIndex + 1;
        const nextClause = searchResults[nextClauseIndex].relevant_clauses;
        setCurrentClauseIndex(nextClauseIndex);
        setHighlightedClauseIndex(nextClauseIndex); // Highlight the clause
        const nextPage = nextClause.Page;
        if (nextPage !== currentPage) {
          setCurrentPage(nextPage);
          fetchImage(nextPage, nextClause.document_name);
        }
        handleHighlightClause(nextClause);
  
        // Scroll to the highlighted clause
        const clauseElement = clauseRefs.current[nextClauseIndex];
        if (clauseElement) {
          clauseElement.scrollIntoView({
            behavior: 'smooth',
            block: 'center', // Align the clause with the top of the container
          });
        }
      }

  };
  
  const handlePreviousClause = () => {
    scrollToStickyFilter(); // Scroll to the sticky filter first
  
      if (currentClauseIndex > 0) {
        const prevClauseIndex = currentClauseIndex - 1;
        const prevClause = searchResults[prevClauseIndex].relevant_clauses;
        setCurrentClauseIndex(prevClauseIndex);
        setHighlightedClauseIndex(prevClauseIndex); // Highlight the clause
        const prevPage = prevClause.Page;
        if (prevPage !== currentPage) {
          setCurrentPage(prevPage);
          fetchImage(prevPage, prevClause.document_name);
        }
        handleHighlightClause(prevClause);
  
        // Scroll to the highlighted clause
        const clauseElement = clauseRefs.current[prevClauseIndex];
        if (clauseElement) {
          clauseElement.scrollIntoView({
            behavior: 'smooth',
            block: 'center', // Align the clause with the top of the container
          });
        }
      }

  };
    

  const handleDownload = async () => {
    try {
      const user_email = user.email;
      const user_id = user.sub;
      const accessToken = await getAccessTokenSilently();
      const { data, error } = await LeasesReviewDownloadLease(accessToken, user_id, user_email);
      if (data) {
        const date = new Date();
        const dateTimeFormat = new Intl.DateTimeFormat('en', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' });        
        const [{ value: month },,{ value: day },,{ value: year },,{ value: hour },,{ value: minute },,{ value: second }] = dateTimeFormat.formatToParts(date);
        const formattedDate = `${year}-${month}-${day}_${hour}-${minute}-${second}`;
        const url = window.URL.createObjectURL(new Blob([data], { type: 'text/csv' }));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${formattedDate}_${user_email}_lease_data.csv`);
        document.body.appendChild(link);
        link.click();
        link.remove();
        window.URL.revokeObjectURL(url);
      } else if (error) {
        console.error("LeasesSearchPage - Download error:", error);
      } else {
        console.log("LeasesSearchPage - No data received for download.");
      }
    } catch (err) {
      console.error("LeasesSearchPage - An error occurred during the download process:", err.message);
    }
  };

  const handleClauseClick = (index, clause) => {
    setHighlightedClauseIndex(index);
    setCurrentClauseIndex(index); // Set the current clause index to the clicked one
  
    // Optionally, highlight the clause in the image as well
    if (clause) {
      handleHighlightClause(clause); // Use the existing handleHighlightClause function
    }
  };
  
  


  if (!user.email_verified) {
    return (
      <PageLayout>
        <div className="content-layout">
          <h1 id="page-title" className="content__title">
            Required: verify your email address
          </h1>
          <h2>Please log into the email account you registered with to verify your email address (search 'LeaseIntellectAI' and check in junk if required).</h2>
        </div>
      </PageLayout>
    );
  }
  
  const baseUrl = process.env.REACT_APP_URL;

  return (
    <PageLayout>
      <div className="content-layout">
        <div className="header-with-button">
          <h1 id="page-title" className="content__title">Lease Search</h1>

        </div>
        <div className="header-with-button">
          <h2>Find relevant clauses in your lease</h2>
          <button className="download__button-icon" onClick={handleDownload} title="Download Leases into a CSV">
            <img src={`${baseUrl}/csv.svg`} alt="Download CSV" className="download-icon" />
          </button>
        </div>
        <div className="content__body">
          <p id="page-description">
            <span>
              Select the <strong>Lease</strong> you would like to review and use the search functionality to find specific clauses or terms in your lease.

            </span>
          </p>
          <div className="filters-wrapper">
          <div className="vertical-label-filters">FILTERS</div>
          <div className="filters-container"> 
            {/* Conditionally render these filters only if is_commercial is true */}
            {isCommercialLease() && (
              <>
              <div className="filter-group">
                <label htmlFor="portfolio-filter">Portfolio</label>
                <select id="portfolio-filter" value={portfolioName} onChange={(e) => {
                  const selectedPortfolio = e.target.value;
                  setPortfolioName(selectedPortfolio);
                  if (selectedPortfolio === "") {
                    // Reset asset and tenant when "All Portfolios" is selected
                    setAssetName("");
                    setTenantName("");
                  }
                }}>
                  <option value="">All Portfolios</option>
                  {portfolioNames.map((portfolio) => (
                    <option key={portfolio} value={portfolio}>{portfolio}</option>
                  ))}
                </select>
              </div>

              <div className="filter-group">
                <label htmlFor="asset-filter">Asset</label>
                <select id="asset-filter" value={assetName} onChange={(e) => {
                  const selectedAsset = e.target.value;
                  setAssetName(selectedAsset);
                  if (selectedAsset === "") {
                    // Reset tenant when "All Assets" is selected
                    setTenantName("");
                  }
                }} disabled={!portfolioName}>
                  <option value="">All Assets</option>
                  {assetNames.map((asset) => (
                    <option key={asset} value={asset}>{asset}</option>
                  ))}
                </select>
              </div>

              <div className="filter-group">
                <label htmlFor="tenant-filter">Tenant</label>
                <select id="tenant-filter" value={tenantName} onChange={(e) => {
                  const selectedTenant = e.target.value;
                  setTenantName(selectedTenant);
                  // No additional reset logic here since tenant filtering is dependent on portfolio and asset selections
                }} disabled={!portfolioName || !assetName}>
                  <option value="">All Tenants</option>
                  {tenantNames.map((tenant) => (
                    <option key={tenant} value={tenant}>{tenant}</option>
                  ))}
                </select>
              </div>

            </>
            )}
            <div className="filter-group">
              <label htmlFor="lease-select">Lease</label>
              <select id="lease-select" value={leaseName} onChange={(e) => setLeaseName(e.target.value)}>
                {leaseNames.map((lease, index) => (
                  <option key={index} value={lease}>{lease}</option>
                ))}
              </select>
            </div>

            </div>
        </div>
        </div>
        
        <div className="sticky-filter">

        <div className="nav-wrapper">
          <div className="vertical-label-navigation">NAVIGATE</div>
          <div className="navigation-buttons">
            <div className="nav-group">
              <label>Page</label>
              <button onClick={handlePreviousPage} disabled={currentPage === 1}>⬇</button>
              <button onClick={handleNextPage} disabled={currentPage === totalPages}>⬆</button>
            </div>
            <div className="nav-group">
              <label>Clause</label>
              <button onClick={handlePreviousClause} disabled={currentClauseIndex === 0}>⬇</button>
              <button onClick={handleNextClause} disabled={totalSearchClauses === 0 || currentClauseIndex >= totalSearchClauses - 1}>⬆</button>
            </div>
            <div className="nav-group delete-group">
              <label>Delete Lease</label>
              <button onClick={() => handleDelete(leaseName)}>X</button>
            </div>
          </div>
          </div>

          <div className="document-info-container">
            <span className="document-name">Document: {documentName}</span>
            <span className="page-number">Page: {currentPage}</span>
          </div>

        </div>  

        <div className="content__body">
          <div className="content-wrapper">

            <div className="search-results-container">
              {message && <div className="error-message">{message}</div>}
                {/* Integrated Search Functionality */}
                <div className="search-input-container">
                  <input
                    type="text"
                    value={query}
                    onChange={(e) => setQuery(e.target.value)}
                    placeholder="Search your lease clauses..."
                    className="search-input"
                  />
                  <button onClick={() => handleSearch(1)} className="search-button">Search</button>
                </div>
  
                <div className="search-results">
                {searchResults.length > 0 ? (
                  <ul className="result-list">
                    {searchResults.map((result, index) => (
                      <li
                        key={index}
                        className={`result-item ${index === highlightedClauseIndex ? 'highlighted-clause' : ''}`}
                        onClick={() => handleClauseClick(index, result.relevant_clauses)}
                      >
                        <h3 className="result-title">{result.title}</h3>
                        <h4 className="result-subtitle">{result.sub_title}</h4>
            
                        {/* Render the text with definition as HTML */}
                        <p
                          className="result-text"
                          dangerouslySetInnerHTML={{ __html: result.text }}
                        />
                      </li>
                    ))}
                  </ul>
                ) : (
                  query && <p className="no-results">No results found.</p>
                )}
              </div>

  
                {loading && <p>Loading...</p>}
  
                {!loading && searchResults.length >= 10 && (
                  <button onClick={loadMoreResults} className="search-button">More Clauses</button>
                )}
              </div>

  
            <div
              className="image-viewer"
              ref={pdfViewerRef}
              style={{ width: '100%', height: '95vh' }}
            ></div>
          </div>
        </div>
  
      </div>
    </PageLayout>
  );
};