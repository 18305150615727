import axios from "axios";

export const callExternalApiWithServerErrorAlerts = async (options) => {
  try {
    const response = await axios(options.config);
    // Success logging, can be sent to a centralized logging service
    console.log(`Success: ${options.config.method} ${options.config.url}`);
    return {
      data: response.data,
      success: true,
    };
  } catch (error) {
    let errorMessage = "HTTP request failed"; // Default message
    let statusCode = null;

    if (axios.isAxiosError(error) && error.response) {
        const { response } = error;
        statusCode = response.status;
        errorMessage = response.data.message || response.statusText || error.message;
    } else {
        errorMessage = error.message;
    }

    return {
      success: false,
      message: errorMessage,
      statusCode: statusCode,
    };
  }
};
