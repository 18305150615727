import React, { useRef, useEffect, useState, useCallback } from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import { PageLayout } from "../components/page-layout";
import { getTokenResource, LeasesReviewGetLeaseNames, LeasesReviewGetLeaseData, LeasesReviewFetchImage, LeasesReviewDeleteLease, LeasesReviewEditLease, LeasesReviewDownloadLease, LeasesReviewGetPortfolioNames, LeasesReviewGetAssetNames, LeasesReviewGetTenantNames, LeasesReviewGetPercentVerified, LeasesReviewGetPercentAnswered } from "../services/message.service";
import '../styles/components/table.css';
import '../styles/components/modal.css';
import '../styles/components/document-image.css';
import '../styles/general.css';
import '../styles/components/dashboard.css';

export const LeasesReviewPage = () => {
  const { getAccessTokenSilently, isAuthenticated, user } = useAuth0();
  const [csrfToken, setCSRFToken] = useState(null);
  const [message, setMessage] = useState("");
  const [hasRelevantClauses, setHasRelevantClauses] = useState(true); 

  const [postGenerativeAIView, setPostGenerativeAIView] = useState([]);
  const [filteredAnswerPostGenerativeAIView, setFilteredAnswerPostGenerativeAIView] = useState([]);
  const [editGenAI, setEditGenAI] = useState({});
  const [genAIId, setGenAIId] = useState(null);

  const [showFeedbackModal, setShowFeedbackModal] = useState(false);
  const [editingField, setEditingField] = useState(null);
  const [feedbackCategory, setFeedbackCategory] = useState('');
  const [feedbackDetail, setFeedbackDetail] = useState('');  

  const [leaseNames, setLeaseNames] = useState([]);
  const [leaseName, setLeaseName] = useState(''); 
  const [portfolioNames, setPortfolioNames] = useState([]); 
  const [portfolioName, setPortfolioName] = useState(''); 
  const [assetNames, setAssetNames] = useState([]); 
  const [assetName, setAssetName] = useState(''); 
  const [tenantNames, setTenantNames] = useState([]); 
  const [tenantName, setTenantName] = useState(''); 
  const [documentName, setDocumentName] = useState(''); 
  const [answerFilter, setAnswerFilter] = useState('all');
  const [selectedVariableCategory, setSelectedVariableCategory] = useState('all');
  
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [currentImage, setCurrentImage] = useState(1);

  const stickyFilterRef = useRef(null);
  const clauseRefs = useRef([]);
  const variableRefs = useRef([]);

  const [currentVariableIndex, setCurrentVariableIndex] = useState(0);
  const [relevantClauses, setRelevantClauses] = useState([]);
  const [currentClauseIndex, setCurrentClauseIndex] = useState(0);
  const [totalClauses, setTotalClauses] = useState(0);
  const [bbox, setBbox] = useState('');
  const [dpi, setDPI] = useState('');
  const [pageWidth, setPageWidth] = useState('');
  const [pageHeight, setPageHeight] = useState('');
  const [percentVerified, setPercentVerified] = useState(0);
  const [percentAnswered, setPercentAnswered] = useState(0);
  
  const isFirstLoad = useRef(true);
  const pdfViewerRef = useRef(null);

  console.log("LeasesReviewPage - Initial State Set");

  useEffect(() => {
    console.log("LeasesReviewPage - Current Page Width:", pageWidth);
    console.log("LeasesReviewPage - Current Page Height:", pageHeight);
    console.log("LeasesReviewPage - Current DPI:", dpi);
    console.log("LeasesReviewPage - Current BBOX:", bbox);

    if (currentImage && pdfViewerRef.current) {
        const img = new Image();
        img.src = currentImage;
        img.onload = () => {
            if (!pdfViewerRef.current) return; // Check if the ref is valid

            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');
            if (!ctx) return;

            // Desired reduction factor (e.g., 37%)
            const reductionFactor = 0.37;
            const reducedWidth = img.width * reductionFactor;
            const reducedHeight = img.height * reductionFactor;

            canvas.width = reducedWidth;
            canvas.height = reducedHeight;

            ctx.drawImage(img, 0, 0, reducedWidth, reducedHeight);

            // Validate pageWidth, pageHeight, and dpi
            if (bbox && Array.isArray(bbox) && pageWidth > 0 && pageHeight > 0 && dpi > 0) {
                const pdfWidth = pageWidth * dpi;
                const pdfHeight = pageHeight * dpi;

                console.log("LeasesReviewPage - Calculated PDF Width:", pdfWidth);
                console.log("LeasesReviewPage - Calculated PDF Height:", pdfHeight);

                if (pdfWidth > 0 && pdfHeight > 0) {
                    const scaleX = reducedWidth / pdfWidth;
                    const scaleY = reducedHeight / pdfHeight;

                    bbox.forEach((box) => {
                        if (box.bbox_orig && box.bbox_orig.length === 4) {
                            const [x0, y0, x1, y1] = box.bbox_orig;

                            // Scale bbox coordinates
                            const scaledX0 = x0 * scaleX;
                            const scaledY0 = y0 * scaleY;
                            const scaledWidth = (x1 - x0) * scaleX;
                            const scaledHeight = (y1 - y0) * scaleY;

                            if (isFinite(scaledX0) && isFinite(scaledY0) && isFinite(scaledWidth) && isFinite(scaledHeight)) {
                                ctx.strokeStyle = 'red';
                                ctx.lineWidth = 2;
                                ctx.strokeRect(scaledX0, scaledY0, scaledWidth, scaledHeight);
                                console.log("LeasesReviewPage - Red Box Drawn at:", scaledX0, scaledY0, scaledWidth, scaledHeight);
                            } else {
                                console.error("LeasesReviewPage - Invalid scaled bounding box values:", {
                                    scaledX0,
                                    scaledY0,
                                    scaledWidth,
                                    scaledHeight,
                                });
                            }
                        } else {
                            console.error("LeasesReviewPage - Invalid BBOX values:", box);
                        }
                    });
                } else {
                    console.error("LeasesReviewPage - Invalid PDF dimensions for scaling:", { pdfWidth, pdfHeight });
                }
            } else {
                console.log("LeasesReviewPage - No valid BBOX or invalid page dimensions. No Red Box Drawn.");
            }

            pdfViewerRef.current.innerHTML = '';
            pdfViewerRef.current.appendChild(canvas);
        };
    }
}, [currentImage, bbox, pageWidth, pageHeight, dpi, pdfViewerRef]);


  const uniqueVariableCategories = ['all', ...new Set(postGenerativeAIView.map(item => item.variable_category))];
  
  const fetchLeaseNames = useCallback(async (selectedPortfolio = '', selectedAsset = '', selectedTenant = '') => {
    console.log("LeasesReviewPage - Fetching Lease Names...");
    try {
      const accessToken = await getAccessTokenSilently();
      const user_id = user.sub;
      const user_email = user.email;
  
      const { data, error } = await LeasesReviewGetLeaseNames(accessToken, user_id, user_email);
  
      if (error) {
        console.error('LeasesReviewPage - Error fetching lease names:', error.message);
      } else if (data && data.lease_names) {
        // Sort the lease names by 'timestamp' in descending order (most recent first)
        const sortedLeaseNames = data.lease_names.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));
  
        // Filter based on selected portfolio, asset, and tenant
        const filteredLeases = sortedLeaseNames.filter(lease =>
          (!selectedPortfolio || lease.portfolio === selectedPortfolio) &&
          (!selectedAsset || lease.asset === selectedAsset) &&
          (!selectedTenant || lease.tenant === selectedTenant)
        );
  
        const uniqueLeaseNames = Array.from(new Set(filteredLeases.map(lease => lease.lease_name)));
  
        setLeaseNames(uniqueLeaseNames);
        console.log("LeasesReviewPage - Unique lease names fetched and sorted by timestamp:", uniqueLeaseNames);
  
        // Set the most recent lease as the default selection
        if (uniqueLeaseNames.length > 0) {
          const selectedLeaseName = uniqueLeaseNames[0]; // The most recent lease name
          setLeaseName(selectedLeaseName);
  
          const associatedDocument = filteredLeases.find(lease => lease.lease_name === selectedLeaseName);
          if (associatedDocument) {
            setDocumentName(associatedDocument.document_name);
          }
        }
      }
    } catch (err) {
      console.error("LeasesReviewPage - Error fetching lease names:", err.message);
    }
  }, [getAccessTokenSilently, user.sub, user.email]);
    

  const fetchPortfolioNames = useCallback(async () => {
    if (!postGenerativeAIView.some(item => item.is_commercial)) {
      return;  // Do nothing if no commercial lease
    }
    console.log("LeasesReviewPage - Fetching Portfolio Names...");
    try {
      const accessToken = await getAccessTokenSilently();
      const user_id = user.sub;
      const user_email = user.email;
      const { data, error } = await LeasesReviewGetPortfolioNames(accessToken, user_email, user_id);

      if (error) {
        console.error('LeasesReviewPage - Error fetching portfolio names:', error.message);
      } else if (data && data.portfolio_names) {
        const uniquePortfolioNames = Array.from(new Set(data.portfolio_names));

        setPortfolioNames(uniquePortfolioNames);
        console.log("LeasesReviewPage - Unique portfolio names fetched:", uniquePortfolioNames);
      }
    } catch (err) {
      console.error("LeasesReviewPage - Error fetching portfolio names:", err.message);
    }
  }, [getAccessTokenSilently, user.sub, user.email, postGenerativeAIView]);

  const fetchAssetNames = useCallback(async () => {
    console.log("LeasesReviewPage - Fetching Asset Names...");
    try {
      const accessToken = await getAccessTokenSilently();
      const user_id = user.sub;
      const user_email = user.email;

      const { data, error } = await LeasesReviewGetAssetNames(accessToken, user_email, user_id, portfolioName);

      if (error) {
        console.error('LeasesReviewPage - Error fetching asset names:', error.message);
      } else if (data && data.asset_names) {
        const uniqueAssetNames = Array.from(new Set(data.asset_names));
        setAssetNames(uniqueAssetNames);
        console.log("LeasesReviewPage - Unique asset names fetched:", uniqueAssetNames);
      }
    } catch (err) {
      console.error("LeasesReviewPage - Error fetching asset names:", err.message);
    }
  }, [getAccessTokenSilently, user.sub, user.email, portfolioName]);

  const fetchTenantNames = useCallback(async () => {
    console.log("LeasesReviewPage - Fetching Tenant Names...");
    try {
      const accessToken = await getAccessTokenSilently();
      const user_id = user.sub;
      const user_email = user.email;

      const { data, error } = await LeasesReviewGetTenantNames(accessToken, user_email, user_id, portfolioName, assetName);

      if (error) {
        console.error('LeasesReviewPage - Error fetching tenant names:', error.message);
      } else if (data && data.tenant_names) {
        const uniqueTenantNames = Array.from(new Set(data.tenant_names));
        setTenantNames(uniqueTenantNames);
        console.log("LeasesReviewPage - Unique tenant names fetched:", uniqueTenantNames);
      }
    } catch (err) {
      console.error("LeasesReviewPage - Error fetching tenant names:", err.message);
    }
  }, [getAccessTokenSilently, user.sub, user.email, portfolioName, assetName]);


  useEffect(() => {
    if (portfolioName) {
      fetchAssetNames();  // Fetch assets related to the selected portfolio
      fetchTenantNames(); // Fetch tenants related to the selected portfolio
      fetchLeaseNames(portfolioName); // Fetch leases related to the selected portfolio
    }
  }, [portfolioName, fetchAssetNames, fetchTenantNames, fetchLeaseNames]);

  useEffect(() => {
    if (assetName) {
      fetchTenantNames(); // Fetch tenants related to the selected asset
      fetchLeaseNames(portfolioName, assetName); // Fetch leases related to the selected portfolio and asset
    }
  }, [assetName, portfolioName, fetchTenantNames, fetchLeaseNames]);

  useEffect(() => {
    if (tenantName) {
      fetchLeaseNames(portfolioName, assetName, tenantName); // Fetch leases related to the selected portfolio, asset, and tenant
    }
  }, [tenantName, assetName, portfolioName, fetchLeaseNames]);



  // Fetch assets based on selected portfolio
  useEffect(() => {
    const fetchAssets = async () => {
      if (portfolioName) {
        try {
          const accessToken = await getAccessTokenSilently();
          const user_id = user.sub;
          const user_email = user.email;

          const { data, error } = await LeasesReviewGetAssetNames(accessToken, user_email, user_id, portfolioName);

          if (error) {
            console.error('Error fetching assets:', error.message);
          } else {
            setAssetNames(data.asset_names || []);
          }
        } catch (err) {
          console.error('Error fetching assets:', err.message);
        }
      }
    };

    fetchAssets();
  }, [portfolioName, getAccessTokenSilently, user.sub, user.email]);

  // Fetch tenants based on selected portfolio and asset
  useEffect(() => {
    const fetchTenants = async () => {
      if (portfolioName || assetName) {
        try {
          const accessToken = await getAccessTokenSilently();
          const user_id = user.sub;
          const user_email = user.email;

          const { data, error } = await LeasesReviewGetTenantNames(accessToken, user_email, user_id, portfolioName, assetName);

          if (error) {
            console.error('Error fetching tenants:', error.message);
          } else {
            setTenantNames(data.tenant_names || []);
          }
        } catch (err) {
          console.error('Error fetching tenants:', err.message);
        }
      }
    };

    fetchTenants();
  }, [portfolioName, assetName, getAccessTokenSilently, user.sub, user.email]);

  // Update leases when a portfolio, asset, or tenant is selected or unselected
  useEffect(() => {
    const fetchLeases = async () => {
      try {
        const accessToken = await getAccessTokenSilently();
        const user_id = user.sub;
        const user_email = user.email;

        const { data, error } = await LeasesReviewGetLeaseNames(accessToken, user_id, user_email);

        if (error) {
          console.error('Error fetching lease names:', error.message);
        } else {
          const sortedLeaseNames = data.lease_names.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));
          const filteredLeases = sortedLeaseNames.filter(lease => 
            (!portfolioName || lease.portfolio === portfolioName) &&
            (!assetName || lease.asset === assetName) &&
            (!tenantName || lease.tenant === tenantName)
          );
  
          const uniqueLeaseNames = Array.from(new Set(filteredLeases.map(lease => lease.lease_name)));
    
          setLeaseNames(uniqueLeaseNames);
          console.log("LeasesReviewPage - Unique lease names fetched and sorted by timestamp:", uniqueLeaseNames);
        }
      } catch (err) {
        console.error('Error fetching lease names:', err.message);
      }
    };

    fetchLeases();
  }, [portfolioName, assetName, tenantName, getAccessTokenSilently, user.sub, user.email]);


  const sortedVariables = filteredAnswerPostGenerativeAIView.sort((a, b) => {
    // Sort by 'answer': non-null answers first, nulls second
    if (a.answer === null && b.answer !== null) return 1;  // 'a' is null, 'b' is non-null, 'a' goes after 'b'
    if (a.answer !== null && b.answer === null) return -1; // 'a' is non-null, 'b' is null, 'a' goes before 'b'
  
    // Sort by 'variable_category'
    const categoryCompare = a.variable_category.localeCompare(b.variable_category);
    if (categoryCompare !== 0) return categoryCompare;
  
    // Sort by 'variable'
    return a.variable.localeCompare(b.variable);
  });
  

  const handleHighlightClause = useCallback(async (clause) => {
    console.log("LeasesReviewPage - Clause to highlight:", clause);
    
    if (!clause || !clause.Page || !clause.BBOX) {
        console.error("LeasesReviewPage - Invalid clause data:", clause);
        setBbox(''); // Reset bounding box
        setCurrentImage(1); // Optionally reset the current image too if needed
        setHasRelevantClauses(false); // Set to false since the clause is invalid
        return;
    }

    const { document_name, Page } = clause; // Destructure to get the document_name and Page
    console.log(`LeasesReviewPage - Highlighting clause on Page ${Page} with BBOX ${clause.BBOX} in document ${document_name}`);

    const user_email = user.email;
    const accessToken = await getAccessTokenSilently();
    console.log("LeasesReviewPage - Fetching image for highlighting");

    // Set document name and other related details here
    setDocumentName(document_name);
    
    // Parse BBOX to extract relevant bounding boxes for the current page
    let parsedBbox = [];
    try {
        parsedBbox = JSON.parse(clause.BBOX).filter((bbox) => bbox.page === Number(Page));
    } catch (e) {
        console.error("LeasesReviewPage - Error parsing BBOX JSON:", e);
    }

    // Parse the page size JSON
    let parsedPageSize = { width_in_inches: 0, height_in_inches: 0 };
    if (clause.page_size_inches) {
        try {
            parsedPageSize = JSON.parse(clause.page_size_inches);
        } catch (e) {
            console.error("LeasesReviewPage - Error parsing page_size_inches:", e);
        }
    }

    // Fetch the image for the specified page
    const { data, error } = await LeasesReviewFetchImage(accessToken, user.sub, user_email, document_name, clause.Page);

    if (data) {
        console.log("Fetched data:", data);
        console.log("Total pages from response (raw):", data.totalPages);
        const totalPages = Number.isInteger(Number(data.totalPages)) ? parseInt(data.totalPages, 10) : 0;
        setTotalPages(totalPages);
        console.log("Total pages set to:", totalPages);

        // Update all related states to make sure we have the necessary data for drawing the bounding box
        setCurrentPage(prevPage => {
            console.log(`LeasesReviewPage - Previous page: ${prevPage}, Setting to nextPage: ${Number(Page)}`);
            return Number(Page);
        });

        setCurrentImage(data.imageUrl);
        setBbox(parsedBbox);
        setDPI(clause.dpi || 72); // Set DPI from the clause, with a default value
        setPageWidth(parsedPageSize.width_in_inches);
        setPageHeight(parsedPageSize.height_in_inches);
        setHasRelevantClauses(true); // Set to true since a valid clause is present

        // Update the pdfViewerRef content with the new image
        if (pdfViewerRef.current) {
            pdfViewerRef.current.innerHTML = '';
            const imgElement = document.createElement('img');
            imgElement.src = data.imageUrl;
            imgElement.alt = `Lease page ${Page}`;
            imgElement.style.width = '100%';
            pdfViewerRef.current.appendChild(imgElement);
        }
    } else {
        setMessage('Error fetching image');
        console.log("LeasesReviewPage - Error fetching image", Page);
        setHasRelevantClauses(false);
    }

    if (error) {
        console.error("LeasesReviewPage - Error fetching image:", error);
    }

    console.log(`LeasesReviewPage - Image fetched successfully for highlighting on Page ${Page} with BBOX ${clause.BBOX} in document ${document_name}`);
}, [getAccessTokenSilently, user.email, user.sub]);




  const fetchImage = useCallback(async (page, documentName) => {
    console.log("LeasesReviewPage - fetchImage function called for page:", page, "and document:", documentName);

    if (!documentName) {
        console.error("LeasesReviewPage - document name is missing.");
        setHasRelevantClauses(false);
        return;
    }

    const user_email = user.email;
    const user_id = user.sub;
    const accessToken = await getAccessTokenSilently();

    const { data, error } = await LeasesReviewFetchImage(accessToken, user_id, user_email, documentName, page);

    if (data) {
        console.log("Fetched data:", data);
        const totalPages = Number.isInteger(Number(data.totalPages)) ? parseInt(data.totalPages, 10) : 0;
        setTotalPages(totalPages);
        console.log("Total pages set to:", totalPages);

        // Set current image, page number, and document name in one go
        setCurrentPage(prevPage => {
            console.log(`LeasesReviewPage - Previous page: ${prevPage}, Setting to nextPage: ${page}`);
            return page;
        });
        setCurrentImage(data.imageUrl);
        setDocumentName(documentName);
        setHasRelevantClauses(true);

        if (pdfViewerRef.current) {
            pdfViewerRef.current.innerHTML = '';
            const imgElement = document.createElement('img');
            imgElement.src = data.imageUrl;
            imgElement.alt = `Lease page ${page}`;
            imgElement.style.width = '100%';
            pdfViewerRef.current.appendChild(imgElement);
        }
    } else {
        setMessage('Error fetching image');
        console.log("LeasesReviewPage - Error fetching image", page);
        setHasRelevantClauses(false);
    }

    if (error) {
        console.error("LeasesReviewPage - Error Fetching Image:", error);
        setMessage(JSON.stringify(error, null, 2));
        setHasRelevantClauses(false);
    }
  }, [getAccessTokenSilently, user.email, user.sub]);


  
  useEffect(() => {
      const filteredList = Array.isArray(postGenerativeAIView) ? postGenerativeAIView.filter(item => item.lease_name === leaseName) : [];
      if (filteredList && filteredList.length > 0) {
        const firstClause = filteredList[0].relevant_clauses;

        // Check if firstClause is a valid array before accessing its length
        if (Array.isArray(firstClause)) {
          setRelevantClauses(firstClause);
          setTotalClauses(firstClause.length);
        } else {
          // If firstClause is null or not an array, handle it appropriately
          setRelevantClauses([]); // Empty the relevant clauses
          setTotalClauses(0); // Set total clauses to 0
        }

      }
  }, [leaseName, postGenerativeAIView]);


     

  const fetchData = useCallback(async () => {
    let isMounted = true;

    const getCSRFToken = async () => {
      const accessToken = await getAccessTokenSilently();
      const tokenData = await getTokenResource(accessToken);
      if (isMounted) {
        if (tokenData.data) {
          setCSRFToken(tokenData.data);
        } else {
          console.error('LeasesReviewPage - CSRF token could not be retrieved.');
        }
      }
    };

    if (isAuthenticated) {
      getCSRFToken();
    }

    const user_id = user.sub;
    const user_email = user.email;
    const accessToken = await getAccessTokenSilently();

    try {
      const { data, error } = await LeasesReviewGetLeaseData(accessToken, user_id, leaseName, user_email);

      console.log("LeasesReviewPage - API Response:", data);

      if (!isMounted) {
        return;
      }

      if (data && Array.isArray(data.ai_posts) && data.ai_posts.length > 0) {
        let sortedData = data.ai_posts;
      
        // Step 1: Sort by 'answer' (non-null first), 'variable_category', and then 'variable'
        sortedData = sortedData.sort((a, b) => {
          // Sort by 'answer': non-null answers first, nulls second
          if (a.answer === null && b.answer !== null) return 1; // 'a' is null, 'b' is non-null, 'a' goes after 'b'
          if (a.answer !== null && b.answer === null) return -1; // 'a' is non-null, 'b' is null, 'a' goes before 'b'
      
          // Sort by 'variable_category'
          const categoryCompare = a.variable_category.localeCompare(b.variable_category);
          if (categoryCompare !== 0) return categoryCompare;
      
          // Sort by 'variable'
          return a.variable.localeCompare(b.variable);
        });
      
        // Step 2: Set the sorted data into postGenerativeAIView
        setPostGenerativeAIView(sortedData);

        // Step 3: Handle relevant clauses for the first item
        if (sortedData && sortedData.length > 0) {
          const firstGenAI = sortedData[0];
          let parsedClauses = [];
          if (firstGenAI.relevant_clauses) {
            if (Array.isArray(firstGenAI.relevant_clauses)) {
              parsedClauses = firstGenAI.relevant_clauses;
            } else {
              try {
                parsedClauses = JSON.parse(firstGenAI.relevant_clauses) || [];
              } catch (error) {
                console.error("LeasesReviewPage - Error parsing relevant clauses JSON:", error);
              }
            }
          }

          console.log("LeasesReviewPage - GenAI List Data:", data.ai_posts);
          console.log("LeasesReviewPage - First Relevant Clauses:", firstGenAI.relevant_clauses);          

          if (Array.isArray(parsedClauses) && parsedClauses.length > 0) {
            console.log("LeasesReviewPage - Parsed Relevant Clauses:", parsedClauses);
            setRelevantClauses(parsedClauses);
            setTotalClauses(parsedClauses.length);
            setCurrentClauseIndex(0);

            // Step 4: Fetch image and draw the red box for the first clause
            handleHighlightClause(parsedClauses[0]);
          } else {
            // No relevant clauses, fetch default page (e.g., page 1)
            console.log("LeasesReviewPage - No relevant clauses, fetching page 1.");
            setBbox(''); // Reset bounding box
            fetchImage(1, documentName); // Fetch page 1 of the document
          }
        }
      } else {
        console.error('LeasesReviewPage - No data or ai_posts returned.');
      }

      if (error) {
        setMessage(JSON.stringify(error, null, 2));
      }

      // Fetch the percentage of verified leases
      const percentVerifiedResponse = await LeasesReviewGetPercentVerified(accessToken, user_email, user_id, leaseName);
      if (percentVerifiedResponse.data) {
        setPercentVerified(percentVerifiedResponse.data.percent_verified);
      } else if (percentVerifiedResponse.error) {
        console.error('LeasesReviewPage - Error fetching percent verified:', percentVerifiedResponse.error);
      }

      // Fetch the percentage of verified leases
      const percentAnsweredResponse = await LeasesReviewGetPercentAnswered(accessToken, user_email, user_id, leaseName);
      if (percentAnsweredResponse.data) {
        setPercentAnswered(percentAnsweredResponse.data.percent_answered);
      } else if (percentAnsweredResponse.error) {
        console.error('LeasesReviewPage - Error fetching percent answered:', percentAnsweredResponse.error);
      }
      
    } catch (err) {
      console.error("LeasesReviewPage - API Request Error:", err.message);
    }

    return () => {
      isMounted = false;
    };
  }, [getAccessTokenSilently, isAuthenticated, user.sub, user.email, leaseName, handleHighlightClause, fetchImage, documentName]);

  
  useEffect(() => {
    if (isAuthenticated && leaseNames.length === 0) {
      fetchLeaseNames();  // Always fetch lease names, regardless of is_commercial
    }
  
    // Fetch portfolios, assets, and tenants only if is_commercial is true
    if (isAuthenticated && postGenerativeAIView.some(item => item.is_commercial)) {
      fetchPortfolioNames();
      fetchAssetNames();
      fetchTenantNames();
    }
  }, [isAuthenticated, leaseNames.length, fetchLeaseNames, fetchPortfolioNames, fetchAssetNames, fetchTenantNames, postGenerativeAIView]);
  
  
  // Only trigger fetchData when leaseName changes, not during variable navigation
  useEffect(() => {
    if (leaseName) {
      // Reset state values when lease changes
      setCurrentPage(1);
      setTotalPages(0);
      setCurrentVariableIndex(0);
      setTotalClauses(0);
      setRelevantClauses([]);
      fetchData(); // Fetch the new data based on the selected lease
    }
  }, [leaseName]); // Remove fetchData from dependencies if possible




  // Combined useEffect for filtering lease data, sorting, and clause highlight
  useEffect(() => {
    if (isFirstLoad.current && postGenerativeAIView.length > 0) {
      console.log("Running initial setup...");
      // Only run this block on initial load
      console.log("LeasesReviewPage - Combined useEffect: Sorting and Filtering...", postGenerativeAIView);

      // Filter the data based on leaseName
      let filteredList = Array.isArray(postGenerativeAIView) ? postGenerativeAIView.filter(item => item.lease_name === leaseName) : [];
    
      console.log("Filtered by Lease Name:", filteredList);

      // Apply answer filter (withAnswer or withoutAnswer)
      if (answerFilter === 'withAnswer') {
        filteredList = filteredList.filter(item => item.answer !== null && item.answer.trim() !== '');
      } else if (answerFilter === 'withoutAnswer') {
        filteredList = filteredList.filter(item => item.answer === null || item.answer.trim() === '');
      }

      console.log("Filtered by Answer:", filteredList);

      // Apply variable category filter
      if (selectedVariableCategory !== 'all') {
        filteredList = filteredList.filter(item => item.variable_category === selectedVariableCategory);
      }
      console.log("Filtered by Variable Category:", filteredList);

      // Sort the filtered data by variable category and answer
      const sortedByAnswer = filteredList.sort((a, b) => {
        // Sort by 'answer': non-null answers first, nulls second
        if (a.answer === null && b.answer !== null) return 1;  // 'a' is null, 'b' is non-null, 'a' goes after 'b'
        if (a.answer !== null && b.answer === null) return -1; // 'a' is non-null, 'b' is null, 'a' goes before 'b'
      
        // Sort by 'variable_category'
        const categoryCompare = a.variable_category.localeCompare(b.variable_category);
        if (categoryCompare !== 0) return categoryCompare;
      
        // Sort by 'variable'
        return a.variable.localeCompare(b.variable);
      });

      console.log("LeasesReviewPage - Sorted and Filtered List:", sortedByAnswer);

      // Set the filtered and sorted data into state
      setFilteredAnswerPostGenerativeAIView(sortedByAnswer);

      // Handle relevant clauses and highlight the first clause
      if (sortedByAnswer.length > 0 && sortedByAnswer[0].relevant_clauses) {
        const firstClause = sortedByAnswer[0].relevant_clauses;
        if (Array.isArray(firstClause) && firstClause.length > 0) {
          setRelevantClauses(firstClause);
          setTotalClauses(firstClause.length);
          handleHighlightClause(firstClause[0]); // Highlight the first clause
        } else {
          console.log("No relevant clauses, fetching page 1 of the document.");
          // No relevant clauses, fetch the first page and reset bbox
          setRelevantClauses([]);
          setTotalClauses(0);
          setBbox(''); // Ensure no red box is drawn
          fetchImage(1, documentName); // Fetch page 1 of the document
        }
      } else {
        // If no clauses exist, reset everything
        setRelevantClauses([]);
        setTotalClauses(0);
        setBbox(''); // Ensure no red box is drawn
        fetchImage(1, documentName);  // Fetch the first page of the document
      }

      isFirstLoad.current = false; // Mark the first load as completed
    }
  }, [postGenerativeAIView, leaseName, answerFilter, selectedVariableCategory, handleHighlightClause, fetchImage, documentName]);

  
 
  // useEffect to filter based on answerFilter and selectedVariableCategory
  useEffect(() => {
    let filteredList = [...postGenerativeAIView]; // Clone the data to avoid mutating the original array

    // Apply the answer filter
    if (answerFilter === 'withAnswer') {
      filteredList = filteredList.filter(item => item.answer !== null && item.answer.trim() !== '');
    } else if (answerFilter === 'withoutAnswer') {
      filteredList = filteredList.filter(item => item.answer === null || item.answer.trim() === '');
    }

    // Apply the variable category filter
    if (selectedVariableCategory !== 'all') {
      filteredList = filteredList.filter(item => item.variable_category === selectedVariableCategory);
    }

    // Sort the filtered data
    const sortedByAnswer = filteredList.sort((a, b) => {
      // Sort by 'answer': non-null answers first, nulls second
      if (a.answer === null && b.answer !== null) return 1;  // 'a' is null, 'b' is non-null, 'a' goes after 'b'
      if (a.answer !== null && b.answer === null) return -1; // 'a' is non-null, 'b' is null, 'a' goes before 'b'
    
      // Sort by 'variable_category'
      const categoryCompare = a.variable_category.localeCompare(b.variable_category);
      if (categoryCompare !== 0) return categoryCompare;
    
      // Sort by 'variable'
      return a.variable.localeCompare(b.variable);
    });

    setFilteredAnswerPostGenerativeAIView(sortedByAnswer); // Update the filtered data in state
  }, [postGenerativeAIView, answerFilter, selectedVariableCategory]);

  const handleDelete = async (timestampFile) => {
    try {
        const userId = user.sub;
        const user_email = user.email;
        
        const accessToken = await getAccessTokenSilently();
        const csrf_token = csrfToken;
        console.log('LeasesReviewPage - CSRF Token at handleDelete:', csrf_token);
        const { data, error } = await LeasesReviewDeleteLease(accessToken, leaseName, userId, user_email, csrf_token);

        if (data) {
            // Call fetchLeaseNames to refresh lease names after deletion
            await fetchLeaseNames(); 
            
            // Check if the deleted lease is the current lease and update the state accordingly
            if (leaseNames.find(lease => lease.lease_name === leaseName)?.timestamp_file === timestampFile) {
                const remainingLeases = leaseNames.filter(lease => lease.timestamp_file !== timestampFile);
                if (remainingLeases && remainingLeases.length > 0) {
                    const mostRecentLease = remainingLeases[0];
                    setLeaseName(mostRecentLease.lease_name);
                    setDocumentName(mostRecentLease.document_name[0]);
                } else {
                    setLeaseName('');
                    setDocumentName('');
                }
            }

            // Optionally delay the fetchData and fetchImage calls to account for database view refresh
            setTimeout(() => {
                fetchData();
                if (leaseName) fetchImage(1, documentName); // Fetch the first page of the selected lease
            }, 2000); // Delay by 2 seconds
        }

        if (error) {
            console.error(error);
            console.error('LeasesReviewPage - handleDelete error:', error.message);
        }
    } catch (err) {
        console.error(err.message);
    }
  };


  const scrollToStickyFilter = () => {
    if (stickyFilterRef.current) {
      stickyFilterRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  };

  const handleNextPage = () => {
    console.log('Current page:', currentPage); // Add this log
    console.log('Total pages:', totalPages); // Add this log
    if (Number(currentPage) < Number(totalPages)) {
      const nextPage = Number(currentPage) + 1;
      console.log('Navigating to next page:', nextPage); // Add this log
      setCurrentPage(nextPage);
      fetchImage(nextPage, documentName);
      setBbox('[{"page": 1, "bbox_orig": [-10, -10, 1500, 1500]}]')
    }
  };
  
  const handlePreviousPage = () => {
    if (Number(currentPage) > 1) {
      const prevPage = Number(currentPage) - 1;
      console.log('Navigating to previous page:', prevPage); // Add this log
      setCurrentPage(prevPage);
      fetchImage(prevPage, documentName);
      setBbox('[{"page": 1, "bbox_orig": [-10, -10, 1500, 1500]}]')
    }
  };
  
  

  const handleNextClause = () => {
    scrollToStickyFilter(); // Scroll to the sticky filter first 
  
    if (currentClauseIndex < totalClauses - 1) {
      const nextClauseIndex = currentClauseIndex + 1;
      const nextClause = relevantClauses[nextClauseIndex];
      setCurrentClauseIndex(nextClauseIndex);
  
      // Extract the page from the bbox data correctly
      let parsedBbox = [];
      if (nextClause.BBOX) {
        try {
          parsedBbox = JSON.parse(nextClause.BBOX).filter((bbox) => bbox.page === Number(nextClause.Page));
        } catch (e) {
          console.error("LeasesReviewPage - Error parsing BBOX JSON:", e);
        }
      }
  
      // If there's a valid bounding box, determine the correct page number
      const nextPage = parsedBbox.length > 0 ? parsedBbox[0].page : Number(nextClause.Page);
      if (nextPage !== Number(currentPage)) {
        console.log(`LeasesReviewPage - Setting currentPage to: ${nextPage}`);
        setCurrentPage(prevPage => {
          console.log(`LeasesReviewPage - Previous page: ${prevPage}, Setting to nextPage: ${nextPage}`);
          return nextPage;
        });
  
        fetchImage(nextPage, documentName);
      }
  
      // Set the parsed bounding box and call handleHighlightClause
      setBbox(parsedBbox);
      handleHighlightClause(nextClause);
  
      // Scroll to the highlighted clause
      const clauseElement = clauseRefs.current[nextClauseIndex];
      if (clauseElement) {
        clauseElement.scrollIntoView({
          behavior: 'smooth',
          block: 'center', // Align the clause with the top of the container
        });
      }
    }
  };
  
  const handlePreviousClause = () => {
    scrollToStickyFilter(); // Scroll to the sticky filter first

    if (currentClauseIndex > 0) {
      const prevClauseIndex = currentClauseIndex - 1;
      const prevClause = relevantClauses[prevClauseIndex];
      setCurrentClauseIndex(prevClauseIndex);
      const prevPage = Number(prevClause.Page);
      if (prevPage !== Number(currentPage)) {
        setCurrentPage(Number(prevPage));
        fetchImage(prevPage, documentName);
      }
      handleHighlightClause(prevClause);

      // Scroll to the highlighted clause
      const clauseElement = clauseRefs.current[prevClauseIndex];
      if (clauseElement) {
        clauseElement.scrollIntoView({
          behavior: 'smooth',
          block: 'center', // Align the clause with the top of the container
        });
      }
    }

  };
  
  const handleNextVariable = async () => {
    if (currentVariableIndex < filteredAnswerPostGenerativeAIView.length - 1) {
      const nextVariableIndex = currentVariableIndex + 1;
      const nextVariable = filteredAnswerPostGenerativeAIView[nextVariableIndex];
      setCurrentVariableIndex(nextVariableIndex);
  
      if (nextVariable.relevant_clauses && nextVariable.relevant_clauses.length > 0) {
        const nextDocumentName = nextVariable.relevant_clauses[0].document_name;
  
        // Ensure document name is updated and image fetched if needed
        if (nextDocumentName !== documentName) {
          setDocumentName(nextDocumentName);
          fetchImage(nextVariable.relevant_clauses[0].Page, nextDocumentName);
        }
  
        // Extract and highlight relevant clauses
        let parsedClauses = nextVariable.relevant_clauses;
        if (!Array.isArray(parsedClauses)) {
          try {
            parsedClauses = JSON.parse(nextVariable.relevant_clauses) || [];
          } catch (e) {
            console.error("LeasesReviewPage - Error parsing relevant clauses JSON:", e);
            parsedClauses = [];
          }
        }
  
        if (parsedClauses.length > 0) {
          // Set the clauses, total clauses count, and highlight the first clause
          setRelevantClauses(parsedClauses);
          setTotalClauses(parsedClauses.length);
          setCurrentClauseIndex(0);
  
          // Extract the bbox for highlighting
          let parsedBbox = [];
          try {
            parsedBbox = JSON.parse(parsedClauses[0].BBOX).filter((bbox) => bbox.page === Number(parsedClauses[0].Page));
          } catch (e) {
            console.error("LeasesReviewPage - Error parsing BBOX JSON:", e);
          }
  
          console.log(`LeasesReviewPage - Setting currentPage to: ${parsedBbox[0]?.page}`);
          setCurrentPage(prevPage => {
            console.log(`LeasesReviewPage - Previous page: ${prevPage}, Setting to page: ${parsedBbox[0]?.page}`);
            return parsedBbox[0]?.page;
          });
  
          setBbox(parsedBbox);
          handleHighlightClause(parsedClauses[0]);
        }
      } else {
        // If no relevant clauses, reset the values
        setRelevantClauses(null);
        setTotalClauses(0);
        setCurrentClauseIndex(0);
        setBbox('-10, -10, 1500, 1500'); // Reset the bounding box to prevent drawing a red box
        setDPI(72); // Reset DPI to default
        setPageWidth('10'); // Clear page dimensions
        setPageHeight('15'); // Clear page dimensions
        fetchImage(1, documentName); // Fetch page 1 of the document
      }
    }
  };
  
  
  const handlePreviousVariable = () => {
    scrollToStickyFilter(); // Scroll to the sticky filter first
  
    if (currentVariableIndex > 0) {
      const prevVariableIndex = Math.max(currentVariableIndex - 1, 0); 
      setCurrentVariableIndex(prevVariableIndex);
      const prevVariable = filteredAnswerPostGenerativeAIView[prevVariableIndex];
  
      // Check if there are relevant clauses for the previous variable
      if (prevVariable.relevant_clauses && prevVariable.relevant_clauses.length > 0) {
        setDocumentName(prevVariable.document_name)
        setRelevantClauses(prevVariable.relevant_clauses);
        setTotalClauses(prevVariable.relevant_clauses.length);
        setCurrentClauseIndex(0);
        handleHighlightClause(prevVariable.relevant_clauses[0]);
      } else {
        // If no relevant clauses, reset them
        setRelevantClauses(null);
        setTotalClauses(0);
        setCurrentClauseIndex(0);
        setBbox('[{"page": 1, "bbox_orig": [-10, -10, 1500, 1500]}]')            // Reset the bounding box to prevent drawing a red box
        setDPI(72);              // Reset DPI to default
        setPageWidth('10');        // Clear page dimensions
        setPageHeight('15');       // Clear page dimensions
        fetchImage(1, documentName); // Fetch page 1 of the document
      }
  
      // Scroll to the highlighted variable
      const variableElement = variableRefs.current[prevVariableIndex];
      if (variableElement) {
        variableElement.scrollIntoView({
          behavior: 'smooth',
          block: 'start', // Align the variable with the top of the container
        });
      }
    }
  };
  
  const handleDownload = async () => {
    try {
      const user_email = user.email;
      const user_id = user.sub;
      const accessToken = await getAccessTokenSilently();
      const { data, error } = await LeasesReviewDownloadLease(accessToken, user_id, user_email);
      if (data) {
        const date = new Date();
        const dateTimeFormat = new Intl.DateTimeFormat('en', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' });        
        const [{ value: month },,{ value: day },,{ value: year },,{ value: hour },,{ value: minute },,{ value: second }] = dateTimeFormat.formatToParts(date);
        const formattedDate = `${year}-${month}-${day}_${hour}-${minute}-${second}`;
        const url = window.URL.createObjectURL(new Blob([data], { type: 'text/csv' }));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${formattedDate}_${user_email}_lease_data.csv`);
        document.body.appendChild(link);
        link.click();
        link.remove();
        window.URL.revokeObjectURL(url);
      } else if (error) {
        console.error("LeasesReviewPage - Download error:", error);
      } else {
        console.log("LeasesReviewPage - No data received for download.");
      }
    } catch (err) {
      console.error("LeasesReviewPage - An error occurred during the download process:", err.message);
    }
  };

  const handleEditChange = (e) => {
    const { name, value } = e.target;
    setEditGenAI(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };
    
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (genAIId) {
        const user_email = user.email;
        const user_id = user.sub;
        const accessToken = await getAccessTokenSilently();
        const csrf_token = csrfToken;
        console.log('LeasesReviewPage - CSRF Token at handleSubmit:', csrf_token);
        const { data, error } = await LeasesReviewEditLease(accessToken, user_id, editGenAI, genAIId, csrf_token, user_email);
        if (data) {
          // Optionally update local state immediately
          const updatedGenAI = data.GenAI;
          const updatedList = postGenerativeAIView.map(genAI => {
            if (genAI.primary_key === genAIId) {
              return { ...genAI, ...updatedGenAI };
            }
            return genAI;
          });
          setPostGenerativeAIView(updatedList);
          
          // Delay fetchData call by 2 seconds
          setTimeout(() => {
            fetchData();
          }, 2000); // 2000 milliseconds = 2 seconds
  
          // Clear editing states
          setEditGenAI({});
          setGenAIId(null);
          setEditingField(null);
        }
        if (error) {
          console.error(JSON.stringify(error, null, 2));
          console.error('LeasesReviewPage - handleSubmit error:', error.message);
        }
      }
    } catch (err) {
      console.error(err.message);
    }
  };

  const validateDataType = (value, dataType) => {
    switch (dataType) {
      case 'VARCHAR(255)':
        return typeof value === 'string';
      case 'Float':
        return !isNaN(Number(value)); // Checks if the value can be converted to a number
      case 'Boolean':
        return value === 'true' || value === 'false' || typeof value === 'boolean';
      case 'Date':
        return !isNaN(Date.parse(value)); // Checks if the value can be parsed as a date
      default:
        return false; // If no specific data type is set, assume it's valid
    }
  };
  
  const handleModalSubmit = async (finalAnswer, finalFeedback) => {
    const user_email = user.email;
    const verifiedDatetime = new Date().toISOString();
  
    const updatedFeedbackGenAI = { ...editGenAI, feedback: finalFeedback, answer_override: finalAnswer };
  
    if (feedbackCategory === "Correct answer") {
      updatedFeedbackGenAI.verified = true;
      updatedFeedbackGenAI.verified_by = user_email;
      updatedFeedbackGenAI.verified_datetime = verifiedDatetime;
    } else if (feedbackCategory !== "Correct answer" && finalAnswer !== '') {
      updatedFeedbackGenAI.verified = true;
      updatedFeedbackGenAI.verified_by = user_email;
      updatedFeedbackGenAI.verified_datetime = verifiedDatetime;
    }
  
    // Find the correct item in postGenerativeAIView using genAIId
    const currentGenAIItem = postGenerativeAIView.find(item => item.primary_key === genAIId);
    
    // If the item exists, get the data_type
    if (currentGenAIItem) {
      const currentDataType = currentGenAIItem.data_type;
      console.info("LeasesReviewPage - data type check for:", currentDataType);

      if (finalAnswer !== '') {
        // Validate data type before submission if an answer is provided
        const valid = validateDataType(finalAnswer, currentDataType);
        if (!valid) {
          alert(`The input is invalid. Expected a value of type ${currentDataType}.`);
          return;
        }
      }
    } else {
      console.error("LeasesReviewPage - Unable to find genAIId in postGenerativeAIView");
      alert("Missing data type for this variable. Please try again.");
      return;
    }
  
    try {
      const accessToken = await getAccessTokenSilently();
      const user_id = user.sub;
      const { data, error } = await LeasesReviewEditLease(accessToken, user_id, updatedFeedbackGenAI, genAIId, csrfToken, user_email);
      if (data) {
        // Optionally update local state immediately
        const updatedList = postGenerativeAIView.map((item) => {
          if (item.primary_key === genAIId) {
            return { ...item, feedback: finalFeedback, ...updatedFeedbackGenAI };
          }
          return item;
        });
        setPostGenerativeAIView(updatedList);  

        // Fetch the percentage of verified leases
        const percentVerifiedResponse = await LeasesReviewGetPercentVerified(accessToken, user_email, user_id, leaseName);
        if (percentVerifiedResponse.data) {
          setPercentVerified(percentVerifiedResponse.data.percent_verified);
        } else if (percentVerifiedResponse.error) {
          console.error('LeasesReviewPage - Error fetching percent verified:', percentVerifiedResponse.error);
        }

        // Fetch the percentage of verified leases
        const percentAnsweredResponse = await LeasesReviewGetPercentAnswered(accessToken, user_email, user_id, leaseName);
        if (percentAnsweredResponse.data) {
          setPercentAnswered(percentAnsweredResponse.data.percent_answered);
        } else if (percentAnsweredResponse.error) {
          console.error('LeasesReviewPage - Error fetching percent answered:', percentAnsweredResponse.error);
        }
        // Delay fetchData call by 2 seconds
        //setTimeout(() => {
          //fetchData();
        //}, 2000); // 2000 milliseconds = 2 seconds
  
        // Reset state related to editing
        setShowFeedbackModal(false);
        setFeedbackCategory('');
        setFeedbackDetail('');
        setEditGenAI({});
        setGenAIId(null);
      } else if (error) {
        console.error('LeasesReviewPage - Error updating feedback:', error.message);
      }
    } catch (err) {
      console.error('LeasesReviewPage - Exception updating feedback:', err.message);
    }
  };
  
  const toggleEdit = (primaryKey, field) => {
    if (field === 'feedback') {
      setGenAIId(primaryKey);
      setShowFeedbackModal(true);
      setFeedbackCategory('');
      setFeedbackDetail('');
    }
  };  


  if (!user.email_verified) {
    return (
      <PageLayout>
        <div className="content-layout">
          <h1 id="page-title" className="content__title">
            Required: verify your email address
          </h1>
          <h2>Please log into the email account you registered with to verify your email address (search 'CRELYTICA' and check in junk if required).</h2>
        </div>
      </PageLayout>
    );
  }


  function VariableItem({
    genAI,
    index,
    currentVariableIndex,
    setRelevantClauses,
    setTotalClauses,
    handleHighlightClause,
    setCurrentVariableIndex,
    toggleEdit,
    handleEditChange,
    handleSubmit,
    editGenAI,
    genAIId,
    editingField,
  }) {
    const [isDefinitionVisible, setIsDefinitionVisible] = useState(false);
    const variableRefs = useRef([]);
  
    // Toggle for showing/hiding definition
    const toggleDefinition = (e) => {
      e.stopPropagation();  // Prevents the click from bubbling up to the parent div
      setIsDefinitionVisible((prevState) => !prevState);
    };
    
  
    return (
        <div
          key={genAI.primary_key}
          ref={(el) => (variableRefs.current[index] = el)}  // Attach ref to each variable item
          className={`variable-item ${index === currentVariableIndex ? 'highlighted-variable' : ''}`}
          onClick={() => {
            if (genAI.relevant_clauses && genAI.relevant_clauses.length > 0) {
              // If relevant_clauses exist, handle them as usual
              setRelevantClauses(genAI.relevant_clauses);
              setTotalClauses(genAI.relevant_clauses.length);
              setCurrentClauseIndex(0);
              handleHighlightClause(genAI.relevant_clauses[0]);
              setCurrentVariableIndex(index);
            } else if (documentName) {
              // If relevant_clauses is null or empty, load page 1 of the document
              console.log("No relevant clauses found, loading page 1.");
              setBbox(''); // Reset bounding box if any
              setCurrentVariableIndex(index); // Set the current variable index
              fetchImage(1, documentName); // Fetch page 1 of the document
            } else {
              console.error("No document name available for fetching the image.");
            }
          }}
        >

        <div className="variable-header">
          <h4 className="variable-category">{genAI.variable_category} - {genAI.variable}</h4>
          {/* Conditionally apply the class based on verification status */}
          <h4 className={`variable-verified ${genAI.verified === true || genAI.verified === 'Verified' ? 'gray' : 'red'}`}>
            {genAI.verified === true || genAI.verified === 'Verified' ? 'Verified' : 'Not Verified'}
          </h4>
        </div>
  
        <div className="variable-header">
          <h3 className="variable-answer">{genAI.answer || 'N/A'}</h3>
        </div>
  
        <h4 className="variable-question">{genAI.question}</h4>
  
        {genAI.reason && <p className="variable-reason">{genAI.reason}</p>}
  
        {genAI.feedback && (
          <div className="variable-feedback">
            <strong>Feedback:</strong> {genAI.feedback}
          </div>
        )}
  
        {/* Feedback Editing Section */}
        {genAIId === genAI.primary_key && editingField === 'feedback' && (
          <>
            <input
              onChange={handleEditChange}
              type="text"
              name="feedback"
              value={editGenAI.feedback}
              className="feedback-input"
            />
            <button type="button" onClick={handleSubmit} className="feedback-submit-button">✓</button>
          </>
        )}
  
        {/* Definition Toggle Section */}
        <div className="definition-toggle">
          <span onClick={(e) => toggleDefinition(e)} className="toggle-button">
            {isDefinitionVisible ? "− Definition" : "+ Definition"}
          </span>
          {isDefinitionVisible && (
            <div className="definition-text">
              <p>{genAI.definition}</p>
            </div>
          )}
        </div>
  
        {/* Edit Icon */}
        <span
          className={`edit-icon ${genAI.verified === 'Verified' ? 'green' : 'red'}`}
          onClick={() => toggleEdit(genAI.primary_key, 'feedback')}
          title="Edit Feedback"
        >
          🖉
        </span>
  
      </div>
    );
  }

  function FeedbackModal({ isOpen, onClose, onSubmit, feedbackCategory, setFeedbackCategory }) {
    const [localFeedbackDetail, setLocalFeedbackDetail] = useState('');
    const [correctAnswer, setCorrectAnswer] = useState('');
    const [isEditingAnswer, setIsEditingAnswer] = useState(false);
    const [editGenAI, setEditGenAI] = useState({});
    const currentGenAIItem = postGenerativeAIView.find(item => item.primary_key === genAIId);
  
    // Retrieve data type if available
    const dataType = currentGenAIItem ? currentGenAIItem.data_type : null;
  
    const handleCategorySelect = (category) => {
      setFeedbackCategory(category);
      // If the category is "Correct answer", do not ask for editing the answer
      setIsEditingAnswer(category !== "Correct answer");
      if (category === "Correct answer") {
        setCorrectAnswer(''); // Clear any previously entered correct answers
      }
    };
  
    const handleDetailChange = (e) => setLocalFeedbackDetail(e.target.value);
  
    const handleCorrectAnswer = (e) => setCorrectAnswer(e.target.value);
  
    const handleAnswerChange = (e) => {
      const { name, value } = e.target;
      setEditGenAI((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    };
  
    const handleModalSubmit = () => {
      const finalFeedback = `${feedbackCategory} - ${localFeedbackDetail}`;
      const finalAnswer = correctAnswer;
      onSubmit(finalAnswer, finalFeedback);
      onClose();
      setLocalFeedbackDetail('');
      setIsEditingAnswer(false);
      setEditGenAI({});
    };
  
    useEffect(() => {
      if (isOpen) {
        setLocalFeedbackDetail('');
        setIsEditingAnswer(false);
        setEditGenAI({});
      }
    }, [isOpen, feedbackCategory]);
  
    if (!isOpen) return null;
  
    return (
      <div className="enhanced-modal">
        <div className="enhanced-modal-content">
          <span className="modal-close" onClick={onClose}>&times;</span>
          {!feedbackCategory ? (
            <>
              <h2>Select a Feedback Category</h2>
              <div className="button-group">
                <button onClick={() => handleCategorySelect("Correct answer")}>Correct answer</button>
                <button onClick={() => handleCategorySelect("The answer does not appear in the lease")}>
                  The answer does not appear in the lease
                </button>
                <button onClick={() => handleCategorySelect("The answer was not found in the lease")}>
                  The answer was not found in the lease
                </button>
                <button onClick={() => handleCategorySelect("The question was answered incorrectly")}>
                  The question was answered incorrectly
                </button>
                <button onClick={() => handleCategorySelect("Other")}>Other</button>
              </div>
            </>
          ) : (
            <>
              <h3>Selected Category: {feedbackCategory}</h3>
              <div className="modal-section">

              {/* Only show the correct answer input if the category is NOT "Correct answer" */}
              {feedbackCategory !== "Correct answer" && (
                <div className="modal-section">
                  <label htmlFor="correct-answer">Enter Correct Answer</label>
                  {dataType === 'Date' && (
                    <input
                      id="correct-answer"
                      type="date"
                      className="modal-input"
                      value={correctAnswer}
                      onChange={handleCorrectAnswer}
                    />
                  )}
                  {dataType === 'Float' && (
                    <input
                      id="correct-answer"
                      type="number"
                      step="any"
                      className="modal-input"
                      value={correctAnswer}
                      onChange={handleCorrectAnswer}
                      placeholder="Enter a number"
                    />
                  )}
                  {dataType === 'Boolean' && (
                    <select
                      id="correct-answer"
                      className="modal-input"
                      value={correctAnswer}
                      onChange={handleCorrectAnswer}
                    >
                      <option value="">Select true or false</option>
                      <option value="true">True</option>
                      <option value="false">False</option>
                    </select>
                  )}
                  {(!dataType || dataType === 'VARCHAR(255)') && (
                    <input
                      id="correct-answer"
                      type="text"
                      className="modal-input"
                      value={correctAnswer}
                      onChange={handleCorrectAnswer}
                      placeholder="Enter text"
                    />
                  )}
                </div>
              )}
                <label htmlFor="detailed-feedback">Detailed Feedback</label>
                <textarea
                  id="detailed-feedback"
                  className="modal-textarea"
                  value={localFeedbackDetail}
                  onChange={handleDetailChange}
                  placeholder="Enter detailed feedback - page number / clause / comment"
                ></textarea>
              </div>

              {/* Show the Edit Answer input if the category is NOT "Correct answer" */}
              {isEditingAnswer && (
                <div className="modal-section">
                  <label htmlFor="edit-answer">Edit Answer</label>
                  <input
                    id="edit-answer"
                    type="text"
                    name="answer"
                    value={editGenAI.answer || ''}
                    onChange={handleAnswerChange}
                    placeholder="Edit answer"
                    className="modal-input"
                  />
                </div>
              )}
  
              <div className="modal-buttons-container">
                <button className="modal-button-submit" onClick={handleModalSubmit}>Submit Feedback</button>
                <button className="modal-button-change" onClick={() => setFeedbackCategory('')}>Change Category</button>
              </div>
            </>
          )}
        </div>
      </div>
    );
  }
  
  const baseUrl = process.env.REACT_APP_URL;
  
  return (
    <PageLayout>
      <div className="content-layout">
        <div className="header-with-button">
          <h1 id="page-title" className="content__title">Lease AI Extraction Review</h1>
        </div>
        <div className="header-with-button">
          <h2>Review, filter and provide feedback on your lease's extraction</h2>
          <button className="download__button-icon" onClick={handleDownload} title="Download Leases into a CSV">
            <img src={`${baseUrl}/csv.svg`} alt="Download CSV" className="download-icon" />
          </button>
        </div>
        <div className="content__body">
          <p id="page-description">
            <span>
              Select the <strong>Lease</strong> you would like to review and the relevant clauses related to each variable are available for your perusal.
            </span>
          </p>
          <div className="filters-wrapper">
          <div className="vertical-label-filters">FILTERS</div>
          <div className="filters-container"> 
            {/* Conditionally render these filters only if is_commercial is true */}
            {filteredAnswerPostGenerativeAIView.some(item => item.is_commercial) && (
              <>
                <div className="filter-group">
                  <label htmlFor="portfolio-filter">Portfolio</label>
                  <select id="portfolio-filter" value={portfolioName} onChange={(e) => {
                    const selectedPortfolio = e.target.value;
                    setPortfolioName(selectedPortfolio);
                    if (selectedPortfolio === "") {
                      // Reset asset and tenant when "All Portfolios" is selected
                      setAssetName("");
                      setTenantName("");
                    }
                  }}>
                    <option value="">All Portfolios</option>
                    {portfolioNames.map((portfolio) => (
                      <option key={portfolio} value={portfolio}>{portfolio}</option>
                    ))}
                  </select>
                </div>

                <div className="filter-group">
                  <label htmlFor="asset-filter">Asset</label>
                  <select id="asset-filter" value={assetName} onChange={(e) => {
                    const selectedAsset = e.target.value;
                    setAssetName(selectedAsset);
                    if (selectedAsset === "") {
                      // Reset tenant when "All Assets" is selected
                      setTenantName("");
                    }
                  }} disabled={!portfolioName}>
                    <option value="">All Assets</option>
                    {assetNames.map((asset) => (
                      <option key={asset} value={asset}>{asset}</option>
                    ))}
                  </select>
                </div>

                <div className="filter-group">
                  <label htmlFor="tenant-filter">Tenant</label>
                  <select id="tenant-filter" value={tenantName} onChange={(e) => {
                    const selectedTenant = e.target.value;
                    setTenantName(selectedTenant);
                    // No additional reset logic here since tenant filtering is dependent on portfolio and asset selections
                  }} disabled={!portfolioName || !assetName}>
                    <option value="">All Tenants</option>
                    {tenantNames.map((tenant) => (
                      <option key={tenant} value={tenant}>{tenant}</option>
                    ))}
                  </select>
                </div>

              </>
            )}
            <div className="filter-group">
              <label htmlFor="lease-select">Lease</label>
              <select id="lease-select" value={leaseName} onChange={(e) => setLeaseName(e.target.value)}>
                {leaseNames.map((lease, index) => (
                  <option key={index} value={lease}>{lease}</option>
                ))}
              </select>
            </div>
            <div className="filter-group">
              <label htmlFor="answer-filter">Answer</label>
              <select id="answer-filter" value={answerFilter} onChange={(e) => setAnswerFilter(e.target.value)}>
                <option value="all">All Rows</option>
                <option value="withAnswer">With Answer</option>
                <option value="withoutAnswer">Without Answer</option>
              </select>
            </div>

            <div className="filter-group">
              <label htmlFor="variable-category-filter">Variable Category</label>
              <select id="variable-category-filter" value={selectedVariableCategory} onChange={(e) => setSelectedVariableCategory(e.target.value)}>
                {uniqueVariableCategories.map((category) => (
                  <option key={category} value={category}>{category === 'all' ? 'All Rows' : category}</option>
                ))}
              </select>
            </div>
          </div>
        </div>
        </div>
        
        <div className="sticky-filter">

        <div className="nav-wrapper">
          <div className="vertical-label-navigation">NAVIGATE</div>
          <div className="navigation-buttons">
            <div className="nav-group">
              <label>Page</label>
              <button onClick={handlePreviousPage} disabled={currentPage === 1}>⬇</button>
              <button onClick={handleNextPage} disabled={currentPage === totalPages}>⬆</button>
            </div>
            <div className="nav-group">
              <label>Variable</label>
              <button onClick={handlePreviousVariable} disabled={currentVariableIndex === 0}>⬇</button>
              <button onClick={handleNextVariable} disabled={currentVariableIndex === filteredAnswerPostGenerativeAIView.length - 1}>⬆</button>
            </div>
            <div className="nav-group">
              <label>Clause</label>
              <button
                onClick={handlePreviousClause}
                disabled={currentClauseIndex === 0 || !filteredAnswerPostGenerativeAIView[currentVariableIndex]?.answer || filteredAnswerPostGenerativeAIView[currentVariableIndex]?.answer === 'false'}
              >
                ⬇
              </button>
              <button
                onClick={handleNextClause}
                disabled={totalClauses === 0 || currentClauseIndex >= totalClauses - 1 || !filteredAnswerPostGenerativeAIView[currentVariableIndex]?.answer || filteredAnswerPostGenerativeAIView[currentVariableIndex]?.answer === 'false'}
              >
                ⬆
              </button>
            </div>
            <div className="nav-group delete-group">
              <label>Delete Lease</label>
              <button onClick={() => handleDelete(leaseName)}>X</button>
            </div>
          </div>
          </div>

          <div className="document-info-container">
            <span className="document-name">Document: {documentName}</span>
            <span className="page-number">Answered (%): {(percentAnswered * 100).toFixed(2)}%</span>
            <span className="page-number">
              <span className="percent-verified-label">Verified (%): </span>
              <span className={`percent-verified-value ${percentVerified === 1 ? 'green' : 'red'}`}>
                {(percentVerified * 100).toFixed(2)}%
              </span>
            </span>
            <span className="page-number">Page: {Number(currentPage)}</span>
          </div>

        </div>  

        <div className="content__body">
          <div className="content-wrapper">
            <div className="variable-results-container">
            {message && <div className="error-message">{message}</div>}

            {/* Use VariableItem to render each variable */}
            {sortedVariables.map((genAI, index) => (
              <VariableItem
                key={genAI.primary_key}
                genAI={genAI}
                currentVariableIndex={currentVariableIndex}
                index={index}
                variableRefs={variableRefs}
                setRelevantClauses={setRelevantClauses}
                setTotalClauses={setTotalClauses}
                setCurrentClauseIndex={setCurrentClauseIndex}
                handleHighlightClause={handleHighlightClause}
                setCurrentVariableIndex={setCurrentVariableIndex}
                genAIId={genAIId}
                editingField={editingField}
                handleEditChange={handleEditChange}
                handleSubmit={handleSubmit}
                toggleEdit={toggleEdit}
                editGenAI={editGenAI}
              />
            ))}
            </div>

          <div
            className="image-viewer"
            ref={pdfViewerRef}
            style={{ width: '100%', height: '95vh' }}
          >
            {hasRelevantClauses && currentImage ? (
              <img src={currentImage} alt={`Lease page ${currentPage}`} style={{ width: '100%' }} />
            ) : (
              <div className="no-relevant-clauses-message">
                No relevant clauses for this variable, please select another variable.
              </div>
            )}
          </div>
          </div>
          </div>
  
        <FeedbackModal
          isOpen={showFeedbackModal}
          onClose={() => setShowFeedbackModal(false)}
          onSubmit={handleModalSubmit}
          feedbackCategory={feedbackCategory}
          setFeedbackCategory={setFeedbackCategory}
          feedbackDetail={feedbackDetail}
          setFeedbackDetail={setFeedbackDetail}
          editGenAI={editGenAI}
          setEditGenAI={setEditGenAI}
        />
      </div>
    </PageLayout>
  );
};