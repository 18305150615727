import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import { refreshSessionId } from '../../services/message.service';

export const LoginButton = () => {
  const { loginWithRedirect, isAuthenticated, user } = useAuth0();

  const handleLogin = async () => {
    await loginWithRedirect({
      appState: {
        returnTo: "/",
      },
      authorizationParams: {
        prompt: "login",
      },
    });
    
    if (isAuthenticated && user) {
      refreshSessionId(user);
    }
  };

  return (
    <button className="button__login" onClick={handleLogin}>
      Log In
    </button>
  );
};