import axios from "axios";

export const callExternalApi = async (options) => {
  try {
    const response = await axios(options.config);
    // Success logging, can be sent to a centralized logging service
    console.log(`Success: ${options.config.method} ${options.config.url}`);
    return {
      data: response.data,
      error: null,
    };
  } catch (error) {
    let errorMessage = "HTTP request failed"; // Default message
    let statusCode = null;
    
    if (axios.isAxiosError(error) && error.response) {
      const { response } = error;
      statusCode = response.status; // Capture status code
      errorMessage = response.data.message || response.statusText || error.message;
      
      // Detailed error logging
      console.error(`Error during ${options.config.method} to ${options.config.url} - Status code: ${statusCode}, Error: ${errorMessage}`);
    } else {
      // Non-Axios error (e.g., network error)
      console.error(`Error during ${options.config.method} to ${options.config.url}: ${error.message}`);
      errorMessage = error.message;
    }

    // Error response, could be sent to a centralized logging service
    return {
      data: null,
      error: {
        message: errorMessage,
        statusCode: statusCode, // Including statusCode in the error response could be useful
      },
    };
  }
};