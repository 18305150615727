import { useFetchCSRFToken } from "./useFetchCSRFToken";
import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import { Route, Routes } from "react-router-dom";
import { PageLoader } from "./components/page-loader";
import { AuthenticationGuard } from "./components/authentication-guard";
import { CallbackPage } from "./pages/callback-page";
import { HomePage } from "./pages/home-page";
import { NotFoundPage } from "./pages/not-found-page";
import { ProfilePage } from "./pages/profile-page";
import { LeasesDataPage } from "./pages/lease-data-page";
import { LeasesReviewPage } from "./pages/leases-review-page";
import { LeasesSearchPage } from "./pages/leases-search-page";
import { AboutUsPage } from "./pages/about-us-page";
import { TermsAndConditions } from "./pages/terms-20231206";
import { PrivacyPolicy } from "./pages/privacy-20240911";
//import { DataRoom } from "./pages/data-room";

export const App = () => {
  const { isLoading } = useAuth0();

  useFetchCSRFToken();

  if (isLoading) {
    return (
      <div className="page-layout">
        <PageLoader />
      </div>
    );
  }

  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/about" element={<AboutUsPage />} />
      <Route path="/terms" element={<TermsAndConditions />} />
      <Route path="/privacy" element={<PrivacyPolicy />} />
      <Route
        path="/review"
        element={<AuthenticationGuard component={LeasesReviewPage} />}
      />
      <Route
        path="/search"
        element={<AuthenticationGuard component={LeasesSearchPage} />}
      />
      <Route
        path="/data"
        element={<AuthenticationGuard component={LeasesDataPage} />}
      />
      <Route
        path="/profile"
        element={<AuthenticationGuard component={ProfilePage} />}
      />
      <Route path="/callback" element={<CallbackPage />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
};