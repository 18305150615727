import React, { useState } from "react";
import { PageLayout } from "../components/page-layout";
import LeaseUploader from "./lease-upload-page";

export const HomePage = () => {
  const [message, setMessage] = useState(null);

  
  return (
    <PageLayout>
      <LeaseUploader />
      {message && <div>Error: {message}</div>}
    </PageLayout>
  );

}