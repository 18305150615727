import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import { MobileNavBarTab } from "./mobile-nav-bar-tab";

export const MobileNavBarTabs = ({ handleClick }) => {
  const { isAuthenticated } = useAuth0();

  return (
    <div className="mobile-nav-bar__tabs">
      {!isAuthenticated && (
        <>
          <MobileNavBarTab
            path="/"
            label="Home"
            handleClick={handleClick}
          />
        </>
      )}
      {isAuthenticated && (
        <>
          <MobileNavBarTab
            path="/"
            label="Upload"
            handleClick={handleClick}
          />
          <MobileNavBarTab
            path="/review"
            label="AI Extraction"
            handleClick={handleClick}
          />
          <MobileNavBarTab
            path="/search"
            label="Search"
            handleClick={handleClick}
          />
          <MobileNavBarTab
            path="/data"
            label="Data"
            handleClick={handleClick}
          />
        </>
      )}
      {!isAuthenticated && (
        <>
          <MobileNavBarTab
            path="/about"
            label="About Us"
            handleClick={handleClick}
          />
        </>
      )}
    </div>
  );
};
