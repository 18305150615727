import React from "react";
import { PageLayout } from "../components/page-layout";

export const AboutUsPage = () => {
  return (
    <PageLayout>
      <div className="content-layout">
        <h1 id="page-title" className="content__title">
          About CRELYTICA
        </h1>
          <div className="content__body">
        <h2>Our Vision</h2>
          <p>
            To eliminate the inefficiency of manual data extraction from real estate investment documents
          </p>       

        <h2>Our AI Lease Extraction Service</h2>
          <p>
            We are continuinuously working to advance our AI document extraction techniques on other real estate investment documents
          </p>
          <ul>
            <li><strong>Real Estate Lease Processing:</strong> Our advanced algorithms quickly extract the lease information that landlords and tenants care about the most, saving you countless hours of reading and analysis.</li>  
            <li><strong>Data Storage Facility:</strong> After extracting the top 25 pieces of information from each lease, we store it in an interactive data table that you can easily edit, download, or delete.</li>  
            <li><strong>Document Extraction Technology:</strong> Our platform performs extensive full-text extraction from PDFs, including scanned or image-based PDFs.</li>  
            <li><strong>Human Verification Process:</strong> While AI provides automation, we place humans at the center of the verification process to ensure accuracy and compliance.</li>  
            <li><strong>Unified Lease Documentation:</strong> Associate multiple documents together to form a single cohesive lease, providing a comprehensive overview of all related information.</li>  
            <li><strong>Comprehensive Search Capability:</strong> Our search functionality allows users to easily search through even image-based PDFs for specific information.</li>  
            <li><strong>Lease Data Standard:</strong> All extracted lease data is licensed under the Creative Commons Attribution-NonCommercial-NoDerivatives 4.0 International license.</li>  
            <li><strong>Robust Security Measures:</strong> We safeguard sensitive information with top-tier 256-bit SSL encryption for all interactions on our platform.</li>
          </ul>

        <h2>Feedback</h2>
          <p>
            Your feedback is instrumental in shaping our services; for suggestions or requests, please reach out to us using the <strong>portal link in the footer.</strong> 
          </p>   

        <h2>Our History</h2>
          <p>
          CRELYTICA, established in 2021, has joined forces with Lease Intellect AI and Docu Intellect AI to redefine the capabilities of document data extraction, automation, and analytics. Our collaborative efforts are focused on developing solutions that meet the complex needs of the real estate industry:
          </p>
          <ul>
            <li><strong>CRELYTICA Ltd:</strong> Data analytics specialists.</li>
            <li><strong>Lease Intellect AI Ltd:</strong> A real estate consultancy that collaborates closely with industry professionals to create bespoke software solutions that address the unique challenges faced by the real estate sector.</li>
            <li><strong>Docu Intellect AI Ltd:</strong> Software developer.</li>
          </ul>
        </div>
      </div>
      {/* JSON-LD Markup for SEO */}
      <script type="application/ld+json">
        {JSON.stringify({
          "@context": "https://schema.org",
          "@type": "Organization",
          "name": "CRELYTICA",
          "url": "https://crelytica.com",
          "logo": "https://crelytica.com/assets/CRELYTICA_logo.png",
          "sameAs": [
            "https://twitter.com/crelytica",
            "https://linkedin.com/company/crelytica"
          ],
          "description": "AI-powered real estate document extraction and analytics.",
          "address": {
            "@type": "PostalAddress",
            "addressLocality": "London",
            "addressCountry": "UK"
          }
        })}
      </script>
    </PageLayout>
  );
};