import React, { useEffect, useState } from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import { PageLayout } from "../components/page-layout";
import { LeasesDataDeleteLease, LeasesDataEditLease, LeasesDataGetLeases, LeasesDataDownloadLease, getTokenResource } from "../services/message.service";
import '../styles/components/table.css';
import '../styles/general.css';

export const LeasesDataPage = () => {
  const [message, setMessage] = useState("");
  const [dataList, setDataList] = useState([]);
  const [editingField, setEditingField] = useState(null);
  const [editData, setEditData] = useState({});
  const [lease, setLease] = useState(null);
  const { getAccessTokenSilently, isAuthenticated, user } = useAuth0();
  const [csrfToken, setCSRFToken] = useState(null);
  const [isCommercialUser, setIsCommercialUser] = useState(false);

  useEffect(() => {
    let isMounted = true;
  
    const getCSRFToken = async () => {
      const accessToken = await getAccessTokenSilently();
      const tokenData = await getTokenResource(accessToken);
      if (isMounted) {
        if (tokenData.data && tokenData.data) {
          setCSRFToken(tokenData.data);
        } else {
          console.error('LeasesDataPage - CSRF token could not be retrieved.');
        }
      }
    };
  
    if (isAuthenticated) {
      getCSRFToken();
    }

     
    const fetchData = async () => {
      const user_id = user.sub;
      const user_email = user.email;
      const accessToken = await getAccessTokenSilently();
      const { data, error } = await LeasesDataGetLeases(accessToken, user_id, user_email);
    
      if (!isMounted) {
        return;
      }
    
      if (data) {
        const sortedData = data.data.sort((a, b) => {
          // Check if the timestamps exist
          if (!a.timestamp || !b.timestamp) {
            return 0; // If either timestamp is missing, treat them as equal
          }
    
          // Parse the timestamps to Date objects for comparison
          const dateA = new Date(a.timestamp);
          const dateB = new Date(b.timestamp);
    
          // Compare the parsed Date objects
          return dateB - dateA; // Sort in descending order (most recent first)
        });

        // Check if any of the dictionaries have is_commercial set to true
        const isAnyCommercial = sortedData.some(item => item.is_commercial === true);
        
        // If any of them are commercial, set the isCommercial state to true
        if (isAnyCommercial) {
          setIsCommercialUser(true);
        }
    
        setDataList(sortedData);
        console.log("LeasesDataPage - sorted data:", sortedData);
      }
    
      if (error) {
        setMessage(JSON.stringify(error, null, 2));
      }
    };
    
    fetchData();
    
    return () => {
      isMounted = false;
    };    
  
  }, [getAccessTokenSilently, isAuthenticated, user.sub, user.email]);
  
  useEffect(() => {
    // New useEffect for handling horizontal scrolling
    const scrollLeftButton = document.querySelector('.scroll-left');
    const scrollRightButton = document.querySelector('.scroll-right');
    const tableContainer = document.querySelector('.table-container');

    if (scrollLeftButton && scrollRightButton && tableContainer) {
      const scrollLeft = () => tableContainer.scrollLeft -= 100;
      const scrollRight = () => tableContainer.scrollLeft += 100;

      scrollLeftButton.addEventListener('click', scrollLeft);
      scrollRightButton.addEventListener('click', scrollRight);

      // Cleanup function
      return () => {
        scrollLeftButton.removeEventListener('click', scrollLeft);
        scrollRightButton.removeEventListener('click', scrollRight);
      };
    }
  }, []); // Empty dependency array means this runs once on mount

  const handleDelete = async (Lease) => {
    try {
      const userId = user.sub;
      const accessToken = await getAccessTokenSilently();
      const csrf_token = csrfToken

      if (!csrfToken) {
        console.error('LeasesDataPage - CSRF token is missing. Please reload the page or log in again.');
        return;
      }
      
      console.log('LeasesDataPage - CSRF Token at handleDelete:', csrfToken);
      const { data, error } = await LeasesDataDeleteLease(accessToken, Lease, userId, csrf_token, user.email);
  
      if (data) {
        const updatedList = dataList.filter(data => data.lease_name !== Lease);
        setDataList(updatedList);
      }
  
      if (error) {
        console.error(error);
        console.error('LeasesDataPage - handleDelete error:', error.message);
      }
    } catch (err) {
      console.error(err.message);
    }
  };


  const formatDate = (dateString) => {
    const options = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' };
    // Ensure dateString is not empty or undefined
    if (!dateString) return '';
    return new Date(dateString).toLocaleDateString('en-GB', options);
  };


  const handleEditChange = (e) => {
    const { name, value } = e.target;
  
    let updatedValue = value;
    if (booleanFields.includes(name)) {
      updatedValue = value === 'true' ? true : value === 'false' ? false : null;
    }
  
    setEditData((prevState) => ({
      ...prevState,
      [name]: updatedValue,
    }));
  };
  
  
  
  const toggleEdit = (data, field) => {
    setLease(data.lease_name); // Still track which lease is being edited
    setEditData({
      [field]: data[field] || "" // Only store the specific field being edited
    });
    setEditingField(field); // Track which field is being edited
  };
  
  
  const handleSubmit = async (e) => {
    e.preventDefault();
  
    try {
      if (lease) {
        const user_email = user.email;
        const user_id = user.sub;
        const accessToken = await getAccessTokenSilently();
        const csrf_token = csrfToken;
  
        // Only send the edited field, its new value, and verification info
        const payload = {
          variable: editingField, // Field being edited (e.g., 'annual_base_rent')
          edited_value: editData[editingField], // New value for that field
          verified_by: user_email, // User who verified
          verified_datetime: new Date().toISOString(), // Timestamp of verification
          verified: true, // Automatically verified if the user edited it
        };
  
        const { data, error } = await LeasesDataEditLease(accessToken, user_id, payload, lease, csrf_token, user_email);
  
        if (data) {
          
          // Update the UI to reflect the change
          const updatedList = dataList.map((item) => {
            if (item.lease_name === lease) {
              return {
                ...item,
                [editingField]: payload.edited_value, // Only update the edited field
                verified: payload.verified,
                verified_by: payload.verified_by,
                verified_datetime: payload.verified_datetime,
              };
            }
            return item;
          });
  
          setDataList(updatedList);
          setEditData({});
          setLease(null);
          setEditingField(null);
        }
  
        if (error) {
          console.error('LeasesDataPage - handleSubmit error:', error.message);
        }
      }
    } catch (err) {
      console.error(err.message);
    }
  };
  

  const handleDownload = async () => {
    try {
      const user_email = user.email;
      const user_id = user.sub;
      const accessToken = await getAccessTokenSilently();
      const { data, error } = await LeasesDataDownloadLease(accessToken, user_id, user.email);

      if (data) {
        const date = new Date();
        const dateTimeFormat = new Intl.DateTimeFormat('en', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' });        
        const [{ value: month },,{ value: day },,{ value: year },,{ value: hour },,{ value: minute },,{ value: second }] = dateTimeFormat.formatToParts(date);
        const formattedDate = `${year}-${month}-${day}_${hour}-${minute}-${second}`;

        const url = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${formattedDate}_${user_email}_lease_data.csv`);
        document.body.appendChild(link);
        link.click();
      }
  
      if (error) {
        console.error(error);
      }
    } catch (err) {
      console.error(err.message);
    }
  };

  // Helper function to display boolean values
  const displayBoolean = (value) => value ? 'True' : 'False'; // Adjust the display values as needed



  if (!user.email_verified) {
    return (
      <PageLayout>
        <div className="content-layout">
          <h1 id="page-title" className="content__title">
            Required: verify your email address
          </h1>
          <h2>Please log into the email account you registered with to verify your email address (search 'CRELYTICA' and check in junk if required).</h2>
        </div>
      </PageLayout>
    );
  }

  const varcharFields = [
    'lease_name',
    'property_address',
    'portfolio_name',
    'asset_name',
    'landlord_name',
    'tenant_trading_name',
    'tenant_name',
    'property_country',
    'property_city',
    'unit_id',
    'unit_name',
    'unit_floor'
  ];
  
  const floatFields = [
    'area_leased',
    'parking_spaces',
    'annual_base_rent',
    'annual_parking_rent',
    'energy_score'
  ];
  
  const booleanFields = [
    'green_saving_incentives',
    'green_data_sharing',
    'green_protection_provision',
    'green_operations_provision',
    'green_renovation_standards',
    'green_certification_goal'
  ];
  
  const dateFields = [
    'lease_start_date',
    'lease_end_date'
  ];

  const handleCancelEdit = () => {
    // Simply reset the editing state without saving changes
    setEditingField(null);
    setEditData({});
  };
  
  const baseUrl = process.env.REACT_APP_URL;

  return (
    <PageLayout>
      <div className="content-layout">
        <h1 id="page-title" className="content__title">
          Leases Rent Roll
        </h1>
        <div className="header-with-button"> {/* Flex container */}
          <h2>Verify, edit, delete and download your rent roll</h2>
          <button className="download__button-icon" onClick={handleDownload} title="Download Leases into a CSV">
            <img src={`${baseUrl}/csv.svg`} alt="Download CSV" className="download-icon" />
          </button>
        </div>
        <div className="content__body">
          <p id="page-description">
            <span>
              To edit the data in a cell, press on the pencil and press on the tick box to save. The edited content needs to align with the variable's data type.
            </span>
          </p>
        </div>
        <div className="content__body">
        {message && <div className="error-message">{message}</div>}
        <div className="table-navigation-container">
          <button className="scroll-left">Left</button>
            <div className="table-container">
              <table className="table">
                <thead>
                  <tr>
                    <th>Delete</th>
                    <th>Lease</th>
                    <th>Verified (%)</th>
                    <th>Property Address</th>
                    {isCommercialUser && <th>Portfolio Name</th>}
                    {isCommercialUser && <th>Asset Name</th>}
                    <th>Landlord Name</th>
                    {isCommercialUser && <th>Tenant Trading Name</th>}
                    <th>Tenant Name</th>
                    <th>Lease Start Date</th>
                    <th>Lease End Date</th>
                    <th>Area Leased</th>
                    <th>Parking Spaces</th>
                    <th>Annual Base Rent</th>
                    <th>Annual Parking Rent</th>

                    <th>Country</th>
                    <th>City</th>
                    <th>Unit ID</th>
                    <th>Unit Name</th>
                    <th>Unit Floor</th>

                    <th>Green Saving Incentives</th>
                    <th>Green Data Sharing</th>
                    <th>Green Protection Provision</th>
                    <th>Green Operations Provision</th>
                    <th>Green Renovation Standards</th>
                    <th>Green Certification Goal</th>
                    <th>Energy Rating</th>
                    <th>Energy Score</th>
                  </tr>
                </thead>
                <tbody>
                  {dataList.map((data) => (
                    <tr key={data.timestamp}>
                      <td>
                        {lease === data.lease_name ? '' : (
                          <div className="nav-group delete-group">
                            <button onClick={() => handleDelete(data.lease_name)}>X</button>
                          </div>
                        )}
                      </td>

                      {[
                        'lease_name',
                        'verified_percentage',
                        'property_address',
                        isCommercialUser ? 'portfolio_name' : null,
                        isCommercialUser ? 'asset_name' : null,
                        'landlord_name',
                        isCommercialUser ? 'tenant_trading_name' : null,
                        'tenant_name',
                        'lease_start_date',
                        'lease_end_date',
                        'area_leased',
                        'parking_spaces',
                        'annual_base_rent',
                        'annual_parking_rent',
                        'property_country',
                        'property_city',
                        'unit_id',
                        'unit_name',
                        'unit_floor',
                        'green_saving_incentives',
                        'green_data_sharing',
                        'green_protection_provision',
                        'green_operations_provision',
                        'green_renovation_standards',
                        'green_certification_goal',
                        'energy_rating',
                        'energy_score'
                      ]
                        .filter(Boolean)
                        .map((field) => (
                          <td key={field}>
                            {/* Disable editing for non-editable fields */}
                            {lease === data.lease_name && editingField === field && !['lease_name', 'portfolio_name', 'asset_name', 'tenant_trading_name', 'verified_percentage'].includes(field) ? (
                              <>
                                {dateFields.includes(field) && (
                                  <>
                                    <input
                                      type="date"
                                      name={field}
                                      value={editData[field]?.split('T')[0] || ''}
                                      onChange={(e) => handleEditChange(e)}
                                      className="form-control"
                                    />
                                    <div className="button-container">
                                      <button type="button" onClick={handleSubmit} className="correct-button">✓</button>
                                      <button type="button" onClick={handleCancelEdit} className="incorrect-button">X</button>
                                    </div>                                  
                                  </>
                                )}

                                {floatFields.includes(field) && (
                                  <>
                                    <input
                                      type="number"
                                      step="any"
                                      name={field}
                                      value={editData[field]}
                                      onChange={(e) => handleEditChange(e)}
                                      className="form-control"
                                    />
                                    <div className="button-container">
                                      <button type="button" onClick={handleSubmit} className="correct-button">✓</button>
                                      <button type="button" onClick={handleCancelEdit} className="incorrect-button">X</button>
                                    </div>                                  
                                  </>
                                )}

                                {booleanFields.includes(field) && (
                                  <>
                                    <select
                                      name={field}
                                      value={editData[field] === true ? 'true' : editData[field] === false ? 'false' : ''}
                                      onChange={(e) => handleEditChange(e)}
                                      className="form-control"
                                    >
                                      <option value="">Select...</option>
                                      <option value="true">True</option>
                                      <option value="false">False</option>
                                    </select>
                                    <div className="button-container">
                                      <button type="button" onClick={handleSubmit} className="correct-button">✓</button>
                                      <button type="button" onClick={handleCancelEdit} className="incorrect-button">X</button>
                                    </div>
                                  </>
                                )}



                                {varcharFields.includes(field) && (
                                  <>
                                    <input
                                      type="text"
                                      name={field}
                                      value={editData[field]}
                                      onChange={(e) => handleEditChange(e)}
                                      className="form-control"
                                    />
                                    <div className="button-container">
                                      <button type="button" onClick={handleSubmit} className="correct-button">✓</button>
                                      <button type="button" onClick={handleCancelEdit} className="incorrect-button">X</button>
                                    </div>                                  
                                  </>
                                )}
                              </>
                            ) : (
                              <>
                                {/* Display data for fields, and remove edit icon for non-editable fields */}
                                {field === 'verified_percentage' ? (
                                  `${(data[field] * 100).toFixed(1)}%`
                                ) : booleanFields.includes(field) ? (
                                  displayBoolean(data[field])
                                ) : dateFields.includes(field) ? (
                                  formatDate(data[field])
                                ) : (
                                  data[field]
                                )}
                                {/* Disable the edit icon for non-editable fields */}
                                {!['lease_name', 'portfolio_name', 'asset_name', 'tenant_trading_name', 'verified_percentage'].includes(field) && (
                                  <span onClick={() => toggleEdit(data, field)} style={{ float: 'right' }}>
                                    🖉
                                  </span>
                                )}
                              </>
                            )}
                          </td>
                        ))}
                    </tr>
                  ))}
                </tbody>

              </table>
            </div>
            <button className="scroll-right">Right</button>
          </div>
        </div>
      </div>
    </PageLayout>
  );
};